import React, {useContext} from 'react';
import {SafeAreaView, ActivityIndicator} from 'react-native';

const LoadingScreen = () => {

    return (
        <SafeAreaView style={{flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'white'}}>
            <ActivityIndicator size="large" color="dodgerblue" />
        </SafeAreaView>
    );
};

export default LoadingScreen;