import axios from 'axios';

const instance = axios.create({
    // baseURL: "https://16f2-196-223-141-205.ngrok.io"
    baseURL: "https://b5d1-102-66-221-206.ngrok.io"
});

instance.interceptors.request.use(
    // 1st function called automatically when about to make a request
    async (config) => {
        // config contains information about the request
        // const token = await AsyncStorage.getItem('token');
        // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MDc4MzQ3MGVmZDE5YTUxYjQ4ODc5MzMiLCJpYXQiOjE2MjI2MzkwMjB9.G9oaF6ZCi553TWEXpC2CCkx2poK6BaavWjtcdGezTsQ"
        // if(token){
        //     config.headers.Authorization = `Bearer ${token}`;
        // }
        // config.headers = {
        //     'access-control-allow-headers': 'authorization,access-control-allow-origin,content-type',
        //     'access-control-allow-origin': 'http://localhost:3000',
        // }
        // config.proxy = {
        //     host: 'https://smartmatter.loca.lt',
        //     port: 19006
        // }
        return config;
    },
    // 2nd function called automatically when making a request e.g. connection error
    (err) => {
        return Promise.reject(err);
    }
);

export default instance;