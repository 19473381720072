import React, {useEffect, useMemo, useState} from 'react';
import { View, Text, StyleSheet, Alert, Image, Dimensions, TextInput, Pressable, ActivityIndicator } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import blouCenterPortrait from '../../images/Delft Blue/Blou 10.png'
import blouCenterLandscape from '../../images/Delft Blue/Blou 9.png'
// import sideImage_1 from '../../images/Johan en Carma/Johan&Carma(71).jpg'
import topImage_1 from '../../images/Johan en Carma/Johan&Carma(104-crop).jpg'
import sideImage_1 from '../../images/Delft Blue/Image 8.jpg'
import headerIconImage from '../../images/Delft Blue/scans_0020.jpg'
import backgroundImage from '../../images/Delft Blue/Image 9.jpg'
import LoadingScreen from "../screens/LoadingScreen";
import { useFonts } from 'expo-font';
import { ScrollView } from 'react-native-gesture-handler';
import WebView from "react-native-webview";
import {Entypo, Ionicons} from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { EvilIcons } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';

import tile_1 from '../../images/Delft Blue/Tile 1.jpg'

// import {Marker} from 'react-native-maps';
// import MapView from 'react-native-web-maps';
import MapView from "react-native-web-google-maps";
import Marker from "react-native-web-google-maps/dist/Marker";


const DetailsScreen = ({navigation}) => {

    const customFontFamily = "BelymonScriptDemo";
    const customBlueTransparent = 'rgba(255,255,255,1)';
    const customBlueTransp = 'rgba(75,107,214,1)'
    const customBlue = 'rgb(75,107,214)';

    const [imageLoaded, updateImageLoaded] = useState(false)

    const [orientation, updateOrientation] = useState('landscape');
    const [height, updateHeight] = useState(Dimensions.get('window').height);
    const [width, updateWidth] = useState(Dimensions.get('window').width);

    const landscapeMiddleViewWidth = width/1.78
    const landscapeSideViewWidth = width/4.65

    // Event Listener for orientation changes
    Dimensions.addEventListener('change', () => {
        console.log("addEventListener")
        updateHeight(Dimensions.get('window').height);
        updateWidth(Dimensions.get('window').width);
    });

    useMemo(() => {
        console.log("useMemo")
        if(width >= height) {
            updateOrientation('landscape')
        } else if(height >= width) {
            updateOrientation('portrait')
        }
        console.log(orientation)
    }, [width, height])

    useEffect(() => {
        console.log("useEffect")
        updateHeight(Dimensions.get('window').height);
        updateWidth(Dimensions.get('window').width);
        if(width >= height) {
            updateOrientation('landscape')
        } else if(height >= width) {
            updateOrientation('portrait')
        }
    }, [])

    useEffect(() => {
        updateImageLoaded(false)
    }, [orientation])

    // useEffect(() => {
    //     navigation.setOptions(
    //         {
    //             tabBarIcon: ({color, size}) => <Entypo name="flower" size={35} color='rgb(75,107,214)' />
    //         }
    //     )
    // }, [])

    if(orientation == 'portrait') {
        return (
            <SafeAreaView style={styles.containerPortrait}>
                {imageLoaded
                    ? null
                    : <View style={{
                        height: height,
                        width: width,
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        backgroundColor: 'white',
                        zIndex: 6
                    }}>
                        {/* <Image
                                    style={{
                                        height: height,
                                        width: width/2
                                    }}
                                    source={border_2}
                                /> */}
                        <View style={styles.overlayView}>
                            <ActivityIndicator size="large" color={customBlue} />
                            <Text style={{
                                textAlign: 'center',
                                marginTop: height*0.02,
                                fontFamily: customFontFamily,
                                fontSize: height*0.05,
                                fontWeight: 'bold',
                                color: customBlue
                            }}
                            >Wag 'n bietjie... #rossouwromanse laai</Text>
                        </View>
                    </View>}
                <View style={styles.graphicsViewPortrait}>
                    {/*<View style={{*/}
                    {/*    height: height,*/}
                    {/*    width: width,*/}
                    {/*    zIndex: 10,*/}
                    {/*    justifyContent: 'center',*/}
                    {/*    position: 'absolute',*/}
                    {/*}}>*/}
                    {/*    <Image*/}
                    {/*        style={{*/}
                    {/*            height: height/1.6,*/}
                    {/*            width: width*/}
                    {/*        }}*/}
                    {/*        source={blouCenterPortrait}*/}
                    {/*    />*/}
                    {/*</View>*/}
                    {/*<Image*/}
                    {/*    style={{*/}
                    {/*        height: height/4,*/}
                    {/*        width: width*/}
                    {/*    }}*/}
                    {/*    source={topImage_1}*/}
                    {/*    onLoad={() => {updateImageLoaded(true)}}*/}
                    {/*/>*/}
                    <Image
                        style={{
                            height: height,
                            width: width
                        }}
                        source={sideImage_1}
                        onLoad={() => {updateImageLoaded(true)}}
                    />
                    {/*<View style={{height: height/2,width: width}} />*/}
                    {/*<Image*/}
                    {/*    style={{*/}
                    {/*        height: height/4,*/}
                    {/*        width: width*/}
                    {/*    }}*/}
                    {/*    source={topImage_1}*/}
                    {/*/>*/}
                </View>
                <View style={[styles.overlayView, {height: height, width: width}]}>
                    <ScrollView
                        style={{
                            // borderWidth: 2,
                            // width: landscapeMiddleViewWidth,
                            // backgroundColor: customBlueTransparent,
                            paddingBottom: height*0.05
                        }}
                        showsVerticalScrollIndicator={false}
                    >
                        <View style={{marginTop: height*0.15, alignItems: "center"}}>
                            {/*<Image*/}
                            {/*    style={{*/}
                            {/*        height: height*0.2,*/}
                            {/*        width: height*0.2*/}
                            {/*        // width: width/3.8*/}
                            {/*    }}*/}
                            {/*    source={headerIconImage}*/}
                            {/*    // onLoad={() => updateImageLoaded(true)}*/}
                            {/*/>*/}
                            {/*<View style={{marginTop: height*0.055, alignItems: 'center', position: 'absolute'}}>*/}
                            <View style={{
                                backgroundColor: customBlueTransparent,
                                padding: width*0.02,
                                width: width,
                                alignItems: 'center'
                            }}>
                                <AntDesign name="clockcircle" size={75} color={customBlue} />
                            </View>
                            <Text
                                style={{
                                    textAlign: 'center',
                                    // marginVertical: height*0.02,
                                    // color: 'white',
                                    color: customBlueTransp,
                                    fontFamily: customFontFamily,
                                    fontSize: height*0.05,
                                    // fontWeight: 'bold',
                                    // backgroundColor: customBlueTransp,
                                    backgroundColor: "white",
                                    paddingBottom: width*0.05,
                                    width: width
                                }}>23 April 2022{"\n"}15:00</Text>
                        </View>
                        <View style={{marginTop: height*0.15, alignItems: "center"}}>
                            {/*<Image*/}
                            {/*    style={{*/}
                            {/*        height: height*0.2,*/}
                            {/*        width: height*0.2*/}
                            {/*        // width: width/3.8*/}
                            {/*    }}*/}
                            {/*    source={headerIconImage}*/}
                            {/*    // onLoad={() => updateImageLoaded(true)}*/}
                            {/*/>*/}
                            {/*<View style={{marginTop: height*0.055, alignItems: 'center', position: 'absolute'}}>*/}
                            <View style={{backgroundColor: customBlueTransparent, width: width, alignItems: 'center'}}>
                            {/*<View style={{backgroundColor: customBlueTransp, width: width, alignItems: 'center'}}>*/}
                                <Ionicons name="location-sharp" size={75} color={customBlue} />
                                {/*<Ionicons name="location-sharp" size={75} color="white" />*/}
                            </View>
                        </View>
                        <Text
                            style={{
                                textAlign: 'center',
                                // marginVertical: height*0.02,
                                // marginTop: height*0.02,
                                color: customBlue,
                                // color: 'white',
                                fontFamily: customFontFamily,
                                fontSize: height*0.05,
                                // fontWeight: 'bold',
                                // backgroundColor: customBlueTransparent,
                                // backgroundColor: customBlueTransp,
                                backgroundColor: 'white'
                            }}>Nooitgedacht Estate, R304, Koelenhof, Stellenbosch, 7600</Text>
                        <View style={{
                            height: height*0.31,
                            width: width,
                            paddingTop: height*0.01,
                            // backgroundColor: customBlueTransp,
                            // backgroundColor: customBlueTransparent,
                            backgroundColor: 'white'
                        }}>
                            {/*<Text>Google Maps</Text>*/}
                            {/*<WebView*/}
                            {/*    style={{height: 500, width: 500, borderColor: 'black', borderWidth: 20, flex: 1}}*/}
                            {/*    // source={{ uri: 'https://www.google.com' }}*/}
                            {/*/>*/}
                            <MapView
                                // provider={PROVIDER_GOOGLE} // remove if not using Google Maps
                                style={{
                                    alignSelf: 'center',
                                    height: height*0.3,
                                    width: width*0.5,
                                    // ...StyleSheet.absoluteFillObject
                                }}
                                region={{
                                    latitude: -33.890353,
                                    longitude: 18.829202,
                                    latitudeDelta: 0.015,
                                    longitudeDelta: 0.0121,
                                }}
                            >
                                <Marker
                                    coordinate={{ latitude : -33.890353 , longitude : 18.829202 }}
                                />
                            </MapView>
                        </View>
                        <Text
                            style={{
                                textAlign: 'center',
                                // margin: height*0.005,
                                color: customBlue,
                                // color: 'white',
                                fontFamily: customFontFamily,
                                fontSize: height*0.025,
                                // fontWeight: 'bold',
                                // backgroundColor: customBlueTransparent,
                                // backgroundColor: customBlueTransp,
                                backgroundColor: 'white'
                            }}>33°53'25.3"S 18°49'45.1"E</Text>

                        <View style={{marginTop: height*0.15, alignItems: "center"}}>
                            <View style={{
                                backgroundColor: customBlueTransparent,
                                padding: width*0.02,
                                width: width,
                                alignItems: 'center'
                            }}>
                                <MaterialCommunityIcons name="hanger" size={75} color={customBlue} />
                            </View>
                            <Text
                                style={{
                                    textAlign: 'center',
                                    color: customBlueTransp,
                                    fontFamily: customFontFamily,
                                    fontSize: height*0.05,
                                    // fontWeight: 'bold',
                                    backgroundColor: "white",
                                    paddingBottom: width*0.05,
                                    width: width
                                }}>Semi-Formeel</Text>
                        </View>

                        <View style={{marginTop: height*0.15, alignItems: "center"}}>
                            <View style={{
                                backgroundColor: customBlueTransparent,
                                padding: width*0.02,
                                width: width,
                                alignItems: 'center'
                            }}>
                                <Feather name="gift" size={75} color={customBlue} />
                                {/*<EvilIcons name="envelope" size={75} color={customBlue} />*/}
                            </View>
                            <View style={{width:width, alignItems: 'center', backgroundColor: "white"}}>
                                <View>
                                    <Text
                                        style={{
                                            textAlign: 'center',
                                            color: customBlueTransp,
                                            fontFamily: customFontFamily,
                                            fontSize: height*0.05,
                                            // fontWeight: 'bold',
                                            backgroundColor: "white",
                                            paddingBottom: width*0.05,
                                            // width: width
                                        }}>Bank: Standard Bank</Text>
                                    <Text
                                        style={{
                                            textAlign: 'center',
                                            color: customBlueTransp,
                                            fontFamily: customFontFamily,
                                            fontSize: height*0.05,
                                            // fontWeight: 'bold',
                                            backgroundColor: "white",
                                            paddingBottom: width*0.05,
                                            // width: width
                                        }}>Rekening Nommer: 187134707</Text>
                                    <Text
                                        style={{
                                            textAlign: 'center',
                                            color: customBlueTransp,
                                            fontFamily: customFontFamily,
                                            fontSize: height*0.05,
                                            // fontWeight: 'bold',
                                            backgroundColor: "white",
                                            paddingBottom: width*0.05,
                                            // width: width
                                        }}>Takkode: 107</Text>
                                        <Text
                                    style={{
                                        textAlign: 'center',
                                        color: customBlueTransp,
                                        fontFamily: customFontFamily,
                                        fontSize: height*0.05,
                                        // fontWeight: 'bold',
                                        backgroundColor: "white",
                                        paddingBottom: width*0.05,
                                        // width: width
                                    }}>Tipe: Spaar</Text>
                                    {/*<Text*/}
                                    {/*    style={{*/}
                                    {/*        textAlign: 'center',*/}
                                    {/*        color: customBlueTransp,*/}
                                    {/*        fontFamily: customFontFamily,*/}
                                    {/*        fontSize: height*0.05,*/}
                                    {/*        // fontWeight: 'bold',*/}
                                    {/*        backgroundColor: "white",*/}
                                    {/*        paddingBottom: width*0.05,*/}
                                    {/*        // width: width*/}
                                    {/*    }}><b>Rekening Tipe:</b> Spaar</Text>*/}
                                </View>
                            </View>
                        </View>
                        <View style={{marginTop: height*0.15, alignItems: "center"}}>
                            <View style={{
                                backgroundColor: customBlueTransparent,
                                padding: width*0.02,
                                width: width,
                                alignItems: 'center'
                            }}>
                                <Entypo name="home" size={75} color={customBlue} />
                            </View>
                            <View style={{width:width, alignItems: 'center', backgroundColor: "white"}}>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        color: customBlueTransp,
                                        fontFamily: customFontFamily,
                                        fontSize: height*0.05,
                                        // fontWeight: 'bold',
                                        backgroundColor: "white",
                                        paddingBottom: width*0.05,
                                        // width: width
                                    }}>Enige verblyf in en om Stellenbosch sal geskik wees.</Text>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        color: customBlueTransp,
                                        fontFamily: customFontFamily,
                                        fontSize: height*0.05,
                                        // fontWeight: 'bold',
                                        backgroundColor: "white",
                                        paddingBottom: width*0.05,
                                        // width: width
                                    }}>Vir voorstelle, kontak gerus vir:</Text>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        color: customBlueTransp,
                                        fontFamily: customFontFamily,
                                        fontSize: height*0.05,
                                        // fontWeight: 'bold',
                                        backgroundColor: "white",
                                        paddingBottom: width*0.05,
                                        // width: width
                                    }}>Carma: 076 139 4311</Text>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        color: customBlueTransp,
                                        fontFamily: customFontFamily,
                                        fontSize: height*0.05,
                                        // fontWeight: 'bold',
                                        backgroundColor: "white",
                                        paddingBottom: width*0.05,
                                        // width: width
                                    }}>Johan: 072 616 0780</Text>
                            </View>
                        </View>

                        {/*<View style={{marginTop: height*0.05, alignItems: "center"}}>*/}
                        {/*    <Image*/}
                        {/*        style={{*/}
                        {/*            height: height*0.2,*/}
                        {/*            width: height*0.2*/}
                        {/*            // width: width/3.8*/}
                        {/*        }}*/}
                        {/*        source={headerIconImage}*/}
                        {/*        // onLoad={() => updateImageLoaded(true)}*/}
                        {/*    />*/}
                        {/*    <View style={{marginTop: height*0.055, alignItems: 'center', position: 'absolute'}}>*/}
                        {/*        <MaterialCommunityIcons name="hanger" size={75} color={customBlue} />*/}
                        {/*    </View>*/}
                        {/*    <Text*/}
                        {/*        style={{*/}
                        {/*            textAlign: 'center',*/}
                        {/*            marginVertical: height*0.02,*/}
                        {/*            color: 'white',*/}
                        {/*            fontFamily: customFontFamily,*/}
                        {/*            fontSize: height*0.05,*/}
                        {/*            fontWeight: 'bold',*/}
                        {/*            backgroundColor: customBlueTransp,*/}
                        {/*            padding: width*0.05*/}
                        {/*        }}>Semi-Formeel</Text>*/}
                        {/*</View>*/}
                    </ScrollView>
                </View>
            </SafeAreaView>
        );
    } else if (orientation == 'landscape') {
        return (
            <SafeAreaView style={styles.containerLandscape}>
                {imageLoaded
                    ? null
                    : <View style={{
                        height: height,
                        width: width,
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        backgroundColor: 'white',
                        zIndex: 6,
                    }}>
                        <View style={styles.overlayView}>
                            <ActivityIndicator size="large" color={customBlue} />
                            <Text style={{
                                textAlign: 'center',
                                marginTop: height*0.02,
                                fontFamily: customFontFamily,
                                fontSize: height*0.05,
                                fontWeight: 'bold',
                                color: customBlue
                            }}
                            >Wag 'n bietjie... #rossouwromanse laai</Text>
                        </View>
                    </View>}
                <View style={styles.graphicsViewLandscape}>
                    {/*<View style={{*/}
                    {/*    height: height,*/}
                    {/*    width: width,*/}
                    {/*    zIndex: 10,*/}
                    {/*    alignItems: 'center',*/}
                    {/*    position: 'absolute'*/}
                    {/*}}>*/}
                    {/*     <Image*/}
                    {/*        style={{*/}
                    {/*            height: height,*/}
                    {/*            width: width/1.6,*/}
                    {/*            position: 'absolute',*/}
                    {/*            resizeMode: 'stretch'*/}
                    {/*        }}*/}
                    {/*        source={blouCenterLandscape}*/}
                    {/*    /> */}
                    {/*</View>*/}
                    <View>
                        <Image
                            style={{
                                height: height,
                                width: landscapeSideViewWidth
                                // width: width/3.8
                            }}
                            source={sideImage_1}
                            onLoad={() => updateImageLoaded(true)}
                        />
                    </View>
                    <View style={{ height: height, width: landscapeMiddleViewWidth }} />
                    {/* <View>
                                <Image
                                    style={{
                                        height: height,
                                        width: width/2
                                    }}
                                    source={blou_3}
                                    onLoad={() => updateImageLoaded(true)}
                                />
                            </View> */}
                    <View>
                        <Image
                            style={{
                                height: height,
                                width: width/4.5
                                // width: width/3.8
                            }}
                            source={sideImage_1}
                        />
                    </View>
                </View>
                <View style={[styles.overlayView, {height: height, width: landscapeMiddleViewWidth}]}>
                    <ScrollView
                        style={{
                            flex: 1,
                            width: landscapeMiddleViewWidth,
                            backgroundColor: customBlueTransparent,
                            paddingBottom: height*0.05
                        }}
                        showsVerticalScrollIndicator={false}
                    >
                        <View style={{marginTop: height*0.1, alignItems: "center"}}>
                            {/*<Image*/}
                            {/*    style={{*/}
                            {/*        height: height*0.2,*/}
                            {/*        width: height*0.2*/}
                            {/*        // width: width/3.8*/}
                            {/*    }}*/}
                            {/*    source={headerIconImage}*/}
                            {/*    // onLoad={() => updateImageLoaded(true)}*/}
                            {/*/>*/}
                            {/*<View style={{marginTop: height*0.055, alignItems: 'center', position: 'absolute'}}>*/}
                            <AntDesign name="clockcircle" size={75} color={customBlue} />
                            {/*</View>*/}
                            <Text
                                style={{
                                    textAlign: 'center',
                                    // height: height*0.03,
                                    // width: width*0.3,
                                    margin: height*0.01,
                                    color: customBlue,
                                    // color: "white",
                                    fontFamily: customFontFamily,
                                    fontSize: height*0.05,
                                    // fontWeight: 'bold',
                                }}>23 April 2022{"\n"}15:00</Text>
                        </View>

                        <View style={{marginTop: height*0.05, alignItems: "center"}}>
                            {/*<Image*/}
                            {/*    style={{*/}
                            {/*        height: height*0.2,*/}
                            {/*        width: height*0.2*/}
                            {/*        // width: width/3.8*/}
                            {/*    }}*/}
                            {/*    source={headerIconImage}*/}
                            {/*    // onLoad={() => updateImageLoaded(true)}*/}
                            {/*/>*/}
                            {/*<View style={{marginTop: height*0.055, alignItems: 'center', position: 'absolute'}}>*/}
                                <Ionicons name="location-sharp" size={75} color={customBlue} />
                            {/*</View>*/}
                        </View>


                        {/*<Text*/}
                        {/*    style={{*/}
                        {/*        textAlign: 'center',*/}
                        {/*        // height: height*0.03,*/}
                        {/*        marginBottom: height*0.04,*/}
                        {/*        paddingLeft: width*0.015,*/}
                        {/*        color: customBlue,*/}
                        {/*        fontFamily: customFontFamily,*/}
                        {/*        fontSize: height*0.09,*/}
                        {/*        fontWeight: 'bold',*/}
                        {/*        marginTop: height*0.04*/}
                        {/*    }}>Waar</Text>*/}
                        <Text
                            style={{
                                textAlign: 'center',
                                // height: height*0.03,
                                // width: width*0.3,
                                margin: height*0.01,
                                color: customBlue,
                                // color: "white",
                                fontFamily: customFontFamily,
                                fontSize: height*0.05,
                                // fontWeight: 'bold',
                            }}>Nooitgedacht Estate, R304, Koelenhof, Stellenbosch, 7600</Text>
                        <View style={{height: height*0.4, width: width*0.2, alignSelf: "center", marginTop: height*0.02}}>
                            {/*<Text>Google Maps</Text>*/}
                            {/*<WebView*/}
                            {/*    style={{height: 500, width: 500, borderColor: 'black', borderWidth: 20, flex: 1}}*/}
                            {/*    // source={{ uri: 'https://www.google.com' }}*/}
                            {/*/>*/}
                            <MapView
                                // provider={PROVIDER_GOOGLE} // remove if not using Google Maps
                                style={{flex: 1, ...StyleSheet.absoluteFillObject}}
                                region={{
                                    latitude: -33.890353,
                                    longitude: 18.829202,
                                    latitudeDelta: 0.015,
                                    longitudeDelta: 0.0121,
                                }}
                            >
                                <Marker
                                    coordinate={{ latitude : -33.890353 , longitude : 18.829202 }}
                                />
                            </MapView>
                        </View>
                        <Text
                            style={{
                                alignSelf: 'center',
                                textAlign: 'center',
                                height: height*0.03,
                                width: width*0.3,
                                // margin: height*0.02,
                                color: customBlue,
                                fontFamily: customFontFamily,
                                fontSize: height*0.03,
                                // fontWeight: 'bold'
                            }}>33°53'25.3"S 18°49'45.1"E</Text>


                        <View style={{marginTop: height*0.1, alignItems: "center"}}>
                            {/*<Image*/}
                            {/*    style={{*/}
                            {/*        height: height*0.2,*/}
                            {/*        width: height*0.2*/}
                            {/*        // width: width/3.8*/}
                            {/*    }}*/}
                            {/*    source={headerIconImage}*/}
                            {/*    // onLoad={() => updateImageLoaded(true)}*/}
                            {/*/>*/}
                            {/*<View style={{marginTop: height*0.055, alignItems: 'center', position: 'absolute'}}>*/}
                                <MaterialCommunityIcons name="hanger" size={75} color={customBlue} />
                            {/*</View>*/}
                            <Text
                                style={{
                                    textAlign: 'center',
                                    // height: height*0.03,
                                    // width: width*0.3,
                                    margin: height*0.01,
                                    color: customBlue,
                                    // color: "white",
                                    fontFamily: customFontFamily,
                                    fontSize: height*0.05,
                                    // fontWeight: 'bold',
                                }}>Semi-Formeel</Text>
                        </View>
                        <View style={{marginTop: height*0.1, alignItems: "center"}}>
                            {/*<Image*/}
                            {/*    style={{*/}
                            {/*        height: height*0.2,*/}
                            {/*        width: height*0.2*/}
                            {/*        // width: width/3.8*/}
                            {/*    }}*/}
                            {/*    source={headerIconImage}*/}
                            {/*    // onLoad={() => updateImageLoaded(true)}*/}
                            {/*/>*/}
                            {/*<View style={{marginTop: height*0.055, alignItems: 'center', position: 'absolute'}}>*/}
                                <Feather name="gift" size={75} color={customBlue} />
                            {/*</View>*/}
                            <Text
                                style={{
                                    textAlign: 'center',
                                    color: customBlue,
                                    fontFamily: customFontFamily,
                                    fontSize: height*0.05,
                                    // fontWeight: 'bold',
                                }}>Bank: Standard Bank</Text>
                            <Text
                                style={{
                                    textAlign: 'center',
                                    color: customBlue,
                                    fontFamily: customFontFamily,
                                    fontSize: height*0.05,
                                    // fontWeight: 'bold',
                                }}>Rekening Nommer: 187134707</Text>
                            <Text
                                style={{
                                    textAlign: 'center',
                                    color: customBlue,
                                    fontFamily: customFontFamily,
                                    fontSize: height*0.05,
                                    // fontWeight: 'bold',
                                }}>Takkode: 107</Text>
                            <Text
                                style={{
                                    textAlign: 'center',
                                    color: customBlue,
                                    fontFamily: customFontFamily,
                                    fontSize: height*0.05,
                                    // fontWeight: 'bold',
                                }}>Tipe: Spaar</Text>
                        </View>
                        <View style={{marginTop: height*0.1, alignItems: "center"}}>
                            <Entypo name="home" size={75} color={customBlue} />
                            <Text
                                style={{
                                    textAlign: 'center',
                                    color: customBlue,
                                    fontFamily: customFontFamily,
                                    fontSize: height*0.05,
                                    // fontWeight: 'bold',
                                }}>Enige verblyf in en om Stellenbosch sal geskik wees.</Text>
                            <Text
                                style={{
                                    textAlign: 'center',
                                    color: customBlue,
                                    fontFamily: customFontFamily,
                                    fontSize: height*0.05,
                                    // fontWeight: 'bold',
                                }}>Vir voorstelle, kontak gerus vir:</Text>
                            <Text
                                style={{
                                    textAlign: 'center',
                                    color: customBlue,
                                    fontFamily: customFontFamily,
                                    fontSize: height*0.05,
                                    // fontWeight: 'bold',
                                }}>Carma: 076 139 4311</Text>
                            <Text
                                style={{
                                    textAlign: 'center',
                                    color: customBlue,
                                    fontFamily: customFontFamily,
                                    fontSize: height*0.05,
                                    // fontWeight: 'bold',
                                }}>Johan: 072 616 0780</Text>
                        </View>
                    </ScrollView>
                </View>
            </SafeAreaView>
        );
    }
}

const styles = StyleSheet.create({
    containerLandscape: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        // borderWidth: 5,
        // borderColor: 'red',
    },
    containerPortrait: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // borderWidth: 5,
        // borderColor: 'red',
    },
    graphicsViewLandscape: {
        flex: 1,
        // height: Dimensions.get("window").height,
        flexDirection: 'row',
    },
    graphicsViewPortrait: {
        flex: 1,
        // width: Dimensions.get("window").width,
        flexDirection: 'column',
        // borderWidth: 5,
        // borderColor: 'red',
    },
    borderStyle: {
        // borderWidth: 5,
        // borderColor: 'blue',
        // height: Dimensions.get("window").height,
        // width: Dimensions.get("window").height
        height: (Dimensions.get("window").width/4)*2,
        width: (Dimensions.get("window").width/4)*2,
    },

    sideImageStyle: {
        // height: Dimensions.get("window").height,
        // width: Dimensions.get("window").height/1.5,
        // height: (Dimensions.get("window").width/4)*1.5,
        // width: Dimensions.get("window").width/4,
        height: Dimensions.get("window").height,
        width: Dimensions.get("window").width/4,
        // borderWidth: 5,
        // borderColor: 'green',
    },
    overlayView: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textStyle: {
        // fontSize: 40,
        fontSize: hp('8%'),
        fontWeight: 'bold',
        color: 'dodgerblue'
    },
    input: {
        height: 40,
        margin: 12,
        borderWidth: 1,
    },
    inputResponsive: {
        height: hp('5%'),
        width: wp('18%'),
        margin: 12,
        borderWidth: 2,
        borderColor: 'dodgerblue'
    },
    buttonText: {
        paddingHorizontal: 15,
        paddingVertical: 5,
        borderRadius: 5,
        borderWidth: 3,
        borderColor: 'dodgerblue',
        // borderColor: 'blue',
        // backgroundColor: 'deepskyblue',
        fontWeight: 'bold',
        fontSize: hp('2%')
    }
})

export default DetailsScreen;
