import React, {useEffect, useMemo, useState} from 'react';
import { View, Text, StyleSheet, Alert, Image, Dimensions, TextInput, Pressable, ActivityIndicator } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import blouCenterPortrait from '../../images/Delft Blue/Blou 10.png'
import blouCenterLandscape from '../../images/Delft Blue/Blou 9.png'
// import sideImage_1 from '../../images/Johan en Carma/Johan&Carma(71).jpg'
import sideImage_1 from '../../images/Johan en Carma/Johan&Carma(121).jpg'
import topImage_1 from '../../images/Johan en Carma/Johan&Carma(104-crop).jpg'
import backgroundImage from '../../images/Delft Blue/Image 8.jpg'
import scrollViewBackground from '../../images/Delft Blue/Image 8.jpg'
import LoadingScreen from "../screens/LoadingScreen";
import { useFonts } from 'expo-font';
import { ScrollView } from 'react-native-gesture-handler';
// import MapView from 'react-native-web-maps';
import MapView from "react-native-web-google-maps";
import headerIconImage from "../../images/Delft Blue/scans_0020.jpg";
import {AntDesign, Ionicons, MaterialCommunityIcons, Entypo, Feather} from "@expo/vector-icons";
import Marker from "react-native-web-google-maps/dist/Marker";
import * as Linking from 'expo-linking';

const AccommodationScreen = ({navigation}) => {

    const customFontFamily = "BelymonScriptDemo";
    // const customBlue = 'rgb(75,107,214)';
    const customBlueTransparent = 'rgba(255,255,255,0.8)';
    const customBlueTransp = 'rgba(75,107,214,0.8)'
    const customBlue = 'rgb(75,107,214)';

    const [imageLoaded, updateImageLoaded] = useState(false)

    const [orientation, updateOrientation] = useState('landscape');
    const [height, updateHeight] = useState(Dimensions.get('window').height);
    const [width, updateWidth] = useState(Dimensions.get('window').width);

    const landscapeMiddleViewWidth = width/1.78
    const landscapeSideViewWidth = width/4.65

        // Event Listener for orientation changes
        Dimensions.addEventListener('change', () => {
            console.log("addEventListener")
            updateHeight(Dimensions.get('window').height);
            updateWidth(Dimensions.get('window').width);
        });
    
        useMemo(() => {
            console.log("useMemo")
            if(width >= height) {
                updateOrientation('landscape')
            } else if(height >= width) {
                updateOrientation('portrait')
            }
            console.log(orientation)
        }, [width, height])
    
        useEffect(() => {
            console.log("useEffect")
            updateHeight(Dimensions.get('window').height);
            updateWidth(Dimensions.get('window').width);
            if(width >= height) {
                updateOrientation('landscape')
            } else if(height >= width) {
                updateOrientation('portrait')
            }
        }, [])
    
        useEffect(() => {
            updateImageLoaded(false)
        }, [orientation])

            if(orientation == 'portrait') {
                return (
                    <SafeAreaView style={styles.containerPortrait}>
                        {imageLoaded
                        ? null
                        : <View style={{
                                height: height, 
                                width: width, 
                                justifyContent: 'center', 
                                alignItems: 'center',
                                position: 'absolute', 
                                backgroundColor: 'white', 
                                zIndex: 6
                            }}>
                                {/* <Image 
                                    style={{
                                        height: height,
                                        width: width/2
                                    }}
                                    source={border_2}
                                /> */}
                                <View style={styles.overlayView}>
                                <ActivityIndicator size="large" color={customBlue} />
                                    <Text style={{
                                            textAlign: 'center', 
                                            marginTop: height*0.02,
                                            fontFamily: customFontFamily,
                                            fontSize: height*0.05,
                                            fontWeight: 'bold',
                                            color: customBlue
                                        }}
                                    >Wag 'n bietjie... #rossouwromanse laai</Text>
                                </View>
                        </View>}
                        <View style={styles.graphicsViewPortrait}>
                            {/*<View style={{*/}
                            {/*    height: height,*/}
                            {/*    width: width,*/}
                            {/*    zIndex: 10, */}
                            {/*    justifyContent: 'center', */}
                            {/*    position: 'absolute',*/}
                            {/*}}>*/}
                            {/*    <Image*/}
                            {/*        style={{*/}
                            {/*            height: height/1.6,*/}
                            {/*            width: width*/}
                            {/*        }}*/}
                            {/*        source={blouCenterPortrait}*/}
                            {/*        onLoad={() => {updateImageLoaded(true)}}*/}
                            {/*    />*/}
                            {/*</View>*/}
                            {/*<Image*/}
                            {/*    style={{*/}
                            {/*        height: height/4,*/}
                            {/*        width: width*/}
                            {/*    }}*/}
                            {/*    source={topImage_1}*/}
                            {/*    onLoad={() => {updateImageLoaded(true)}}*/}
                            {/*/>*/}
                            {/*<Image*/}
                            {/*    style={{*/}
                            {/*        height: 3*(height/4),*/}
                            {/*        width: width*/}
                            {/*    }}*/}
                            {/*    source={backgroundImage}*/}
                            {/*    onLoad={() => {updateImageLoaded(true)}}*/}
                            {/*/>*/}
                            <Image
                                style={{
                                    height: height,
                                    width: width
                                }}
                                source={backgroundImage}
                                onLoad={() => {updateImageLoaded(true)}}
                            />
                            {/*<View style={{height: height/2,width: width}} />*/}
                            {/*<Image*/}
                            {/*    style={{*/}
                            {/*        height: height/4,*/}
                            {/*        width: width*/}
                            {/*    }}*/}
                            {/*    source={topImage_1}*/}
                            {/*/>*/}
                        </View>
                        <View style={styles.overlayView}>
                            <ScrollView
                                style={{
                                    paddingBottom: height*0.05
                                }}
                                showsVerticalScrollIndicator={false}
                            >
                                <View style={{marginTop: height*0.15, alignItems: "center"}}>
                                    <View style={{backgroundColor: "white", width: width, alignItems: 'center'}}>
                                        <Entypo name="home" size={75} color={customBlue} />
                                    </View>
                                </View>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        color: customBlue,
                                        fontFamily: customFontFamily,
                                        fontSize: height*0.05,
                                        fontWeight: 'bold',
                                        backgroundColor: 'white'
                                    }}>61 Rhodes-North</Text>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        color: customBlue,
                                        fontFamily: customFontFamily,
                                        fontSize: height*0.03,
                                        fontWeight: 'bold',
                                        backgroundColor: 'white'
                                    }}>Self Catering Apartment & Studio</Text>
                                <Pressable
                                    onPress={() => {
                                        Linking.openURL('http://16rhodesnorth.co.za/');
                                    }}
                                    style={({ pressed }) => [
                                        {
                                            backgroundColor: pressed
                                                ? 'rgb(210, 230, 255)'
                                                : 'white'
                                        },
                                        styles.wrapperCustom
                                    ]}>
                                    {({ pressed }) => (
                                        <View style={{
                                            flexDirection: 'row',
                                            width: width,
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <Text
                                                style={{
                                                    textAlign: 'center',
                                                    color: customBlue,
                                                    fontFamily: customFontFamily,
                                                    fontSize: height*0.03,
                                                    fontWeight: 'bold',
                                                    backgroundColor: 'white',
                                                    marginEnd: width*0.02
                                                }}>http://16rhodesnorth.co.za</Text>
                                            <Feather name="external-link" size={16} color={customBlue} />
                                        </View>
                                    )}
                                </Pressable>

                            </ScrollView>
                        </View>
                    </SafeAreaView>
                );
            } else if (orientation == 'landscape') {
                return (
                    <SafeAreaView style={styles.containerLandscape}>
                        {imageLoaded
                        ? null
                        : <View style={{
                                height: height,
                                width: width, 
                                justifyContent: 'center', 
                                alignItems: 'center',
                                position: 'absolute', 
                                // backgroundColor: 'white',
                                zIndex: 6,
                            }}>
                                <View style={styles.overlayView}>
                                    <ActivityIndicator size="large" color={customBlue} />
                                    <Text style={{
                                            textAlign: 'center', 
                                            marginTop: height*0.02,
                                            fontFamily: customFontFamily,
                                            fontSize: height*0.05,
                                            fontWeight: 'bold',
                                            color: customBlue
                                        }}
                                    >Wag 'n bietjie... #rossouwromanse laai</Text>
                                </View>
                        </View>}
                        <View style={styles.graphicsViewLandscape}>
                            <View style={{
                                height: height,
                                width: width,
                                zIndex: 10,
                                alignItems: 'center',
                                position: 'absolute'
                            }}>
                                <Image
                                    style={{
                                        height: height,
                                        width: width*0.45,
                                        position: 'absolute',
                                        resizeMode: 'stretch',
                                    }}
                                    source={scrollViewBackground}
                                />
                            </View>
                            <View>
                                <Image
                                    style={{
                                        height: height,
                                        width: width*0.275
                                    }}
                                    source={sideImage_1}
                                    onLoad={() => updateImageLoaded(true)}
                                />
                            </View>
                            <View style={{ height: height, flex:3, width: width*0.45}} />
                            <View>
                                <Image
                                    style={{
                                        height: height,
                                        width: width*0.275
                                        // width: width/3.8
                                    }}
                                    source={sideImage_1}
                                />
                            </View>
                        </View>
                        <View style={[styles.overlayView, {height: height, width: width*0.45, marginLeft: width*0.275}]}>
                            <ScrollView
                                style={{
                                    flex: 1,
                                    width: width*0.45,
                                    // borderWidth: 5,
                                    // borderColor: 'red',
                                    // backgroundColor: customBlueTransparent,
                                    paddingBottom: height*0.05,
                                }}
                                showsVerticalScrollIndicator={false}
                            >
                                <View style={{marginTop: height*0.15, alignItems: "center"}}>
                                    <View style={{backgroundColor: "white", width: width*0.45, alignItems: 'center'}}>
                                        <Entypo name="home" size={75} color={customBlue} />
                                    </View>
                                </View>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        color: customBlue,
                                        fontFamily: customFontFamily,
                                        fontSize: height*0.05,
                                        fontWeight: 'bold',
                                        backgroundColor: 'white',
                                        width: width*0.45,
                                    }}>61 Rhodes-North</Text>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        color: customBlue,
                                        fontFamily: customFontFamily,
                                        fontSize: height*0.03,
                                        fontWeight: 'bold',
                                        backgroundColor: 'white',
                                        width: width*0.45,
                                    }}>Self Catering Apartment & Studio</Text>
                                <Pressable
                                    onPress={() => {
                                        Linking.openURL('http://16rhodesnorth.co.za/');
                                    }}
                                    style={({ pressed }) => [
                                        {
                                            backgroundColor: pressed
                                                ? 'rgb(210, 230, 255)'
                                                : 'white'
                                        },
                                        styles.wrapperCustom
                                    ]}>
                                    {({ pressed }) => (
                                        <View style={{
                                            flexDirection: 'row',
                                            width: width*0.45,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginBottom: height*0.02
                                        }}>
                                            <Text
                                                style={{
                                                    textAlign: 'center',
                                                    color: customBlue,
                                                    fontFamily: customFontFamily,
                                                    fontSize: height*0.03,
                                                    fontWeight: 'bold',
                                                    backgroundColor: 'white',
                                                    marginEnd: width*0.02
                                                }}>http://16rhodesnorth.co.za</Text>
                                            <Feather name="external-link" size={16} color={customBlue} />
                                        </View>
                                    )}
                                </Pressable>
                            </ScrollView>
                        </View>
                    </SafeAreaView>
                );
            }
        }

const styles = StyleSheet.create({
    containerLandscape: {
        flex: 1, 
        flexDirection: 'row',
        // justifyContent: 'center',
        // alignItems: 'center',
        // borderWidth: 5,
        // borderColor: 'red',
    },
    containerPortrait: {
        flex: 1, 
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'center', 
        // borderWidth: 5,
        // borderColor: 'red',
    },
     graphicsViewLandscape: {
        flex: 1, 
        // height: Dimensions.get("window").height,
        flexDirection: 'row',
    },
    graphicsViewPortrait: {
        flex: 1, 
        // width: Dimensions.get("window").width,
        flexDirection: 'column',
        // borderWidth: 5,
        // borderColor: 'red',
    },
    borderStyle: {
        // borderWidth: 5,
        // borderColor: 'blue',
        // height: Dimensions.get("window").height,
        // width: Dimensions.get("window").height
        height: (Dimensions.get("window").width/4)*2,
        width: (Dimensions.get("window").width/4)*2,
    },

    sideImageStyle: {
        // height: Dimensions.get("window").height,
        // width: Dimensions.get("window").height/1.5,
        // height: (Dimensions.get("window").width/4)*1.5,
        // width: Dimensions.get("window").width/4,
        height: Dimensions.get("window").height,
        width: Dimensions.get("window").width/4,
        // borderWidth: 5,
        // borderColor: 'green',
    },
    overlayView: {
        position: 'absolute',
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: 'white'
    },
    textStyle: {
        // fontSize: 40,
        fontSize: hp('8%'),
        fontWeight: 'bold',
        color: 'dodgerblue'
    },
    input: {
        height: 40,
        margin: 12,
        borderWidth: 1,
      },
    inputResponsive: {
        height: hp('5%'),
        width: wp('18%'),
        margin: 12,
        borderWidth: 2,
        borderColor: 'dodgerblue'
    },
    buttonText: {
        paddingHorizontal: 15, 
        paddingVertical: 5, 
        borderRadius: 5, 
        borderWidth: 3, 
        borderColor: 'dodgerblue',
        // borderColor: 'blue',
        // backgroundColor: 'deepskyblue',
        fontWeight: 'bold',
        fontSize: hp('2%')
      }
})

export default AccommodationScreen;
