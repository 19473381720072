import React, {useEffect, useMemo, useState} from 'react';
import { View, Text, StyleSheet, Alert, Image, Dimensions, TextInput, Pressable } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import border_2 from '../../images/Delft Blue/Border_2.jpg'
import sideImage_1 from '../../images/Johan en Carma/Johan&Carma(6).jpg'
import topImage_1 from '../../images/Johan en Carma/Johan&Carma(3).jpg'
import { ActivityIndicator } from 'react-native';
import { Button } from 'react-native-elements/dist/buttons/Button';

const FailedRSVP = ({navigation}) => {
    const [orientation, updateOrientation] = useState('landscape');
    const [height, updateHeight] = useState(Dimensions.get('window').height);
    const [width, updateWidth] = useState(Dimensions.get('window').width);
    const [requestProgress, updateRequestProgress] = useState(false);

    // Event Listener for orientation changes
    Dimensions.addEventListener('change', () => {
        console.log("addEventListener")
        updateHeight(Dimensions.get('window').height);
        updateWidth(Dimensions.get('window').width);
    });

    useMemo(() => {
        console.log("useMemo")
        if(width >= height) {
            updateOrientation('landscape')
        } else if(height >= width) {
            updateOrientation('portrait')
        }
        console.log(orientation)
    }, [width, height])

    useEffect(() => {
        console.log("useEffect")
        updateHeight(Dimensions.get('window').height);
        updateWidth(Dimensions.get('window').width);
        if(width >= height) {
            updateOrientation('landscape')
        } else if(height >= width) {
            updateOrientation('portrait')
        }
    }, [])

    if(orientation == 'portrait') {
        return (
            <SafeAreaView style={styles.containerPortrait}>
                <View style={styles.graphicsViewPortrait}>
                    <Image
                        style={{
                            height: height/4,
                            width: width
                        }}
                        source={topImage_1}
                    />
                    <Image
                        style={{
                            height: height/2,
                            width: width
                        }}
                        source={border_2}
                    />
                    <Image
                        style={{
                            height: height/4,
                            width: width
                        }}
                        source={topImage_1}
                    />
                </View>
                <View style={styles.overlayView}>
                    <Text style={{
                        fontSize: height*0.04,
                        fontWeight: 'bold',
                        color: 'dodgerblue'
                    }}>RSVP failed. Please try again!</Text>
                </View>
            </SafeAreaView>
        );
    } else if (orientation == 'landscape') {
        return (
            <SafeAreaView style={styles.containerLandscape}>
                <View style={styles.graphicsViewLandscape}>
                    <Image 
                        style={{
                            height: height,
                            width: width/4
                        }}
                        source={sideImage_1}
                    />
                    <Image 
                        style={{
                            height: height,
                            width: width/2
                        }}
                        source={border_2}
                    />
                    <Image 
                        style={{
                            height: height,
                            width: width/4
                        }}
                        source={sideImage_1}
                    />
                </View>
                <View style={styles.overlayView}>
                    <Text style={{
                        fontSize: height*0.04,
                        fontWeight: 'bold',
                        color: 'dodgerblue'
                    }}>RSVP failed. PleasetTry again!</Text>
                    <Button 
                        title="Back to Save the Date"
                    />
                </View>
            </SafeAreaView>
        );
    }
};

const styles = StyleSheet.create({
    containerLandscape: {
        flex: 1, 
        flexDirection: 'row',
        justifyContent: 'center', 
        alignItems: 'center', 
        // borderWidth: 5,
        // borderColor: 'red',
    },
    containerPortrait: {
        flex: 1, 
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'center', 
        // borderWidth: 5,
        // borderColor: 'red',
    },
    graphicsViewLandscape: {
        flex: 1, 
        // height: Dimensions.get("window").height,
        flexDirection: 'row',
    },
    graphicsViewPortrait: {
        flex: 1, 
        // width: Dimensions.get("window").width,
        flexDirection: 'column',
        // borderWidth: 5,
        // borderColor: 'red',
    },
    borderStyle: {
        // borderWidth: 5,
        // borderColor: 'blue',
        // height: Dimensions.get("window").height,
        // width: Dimensions.get("window").height
        height: (Dimensions.get("window").width/4)*2,
        width: (Dimensions.get("window").width/4)*2,
    },

    sideImageStyle: {
        // height: Dimensions.get("window").height,
        // width: Dimensions.get("window").height/1.5,
        // height: (Dimensions.get("window").width/4)*1.5,
        // width: Dimensions.get("window").width/4,
        height: Dimensions.get("window").height,
        width: Dimensions.get("window").width/4,
        // borderWidth: 5,
        // borderColor: 'green',
    },
    overlayView: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textStyle: {
        // fontSize: 40,
        fontSize: hp('8%'),
        fontWeight: 'bold',
        color: 'dodgerblue'
    },
    input: {
        height: 40,
        margin: 12,
        borderWidth: 1,
      },
    inputResponsive: {
        height: hp('5%'),
        width: wp('18%'),
        margin: 12,
        borderWidth: 2,
        borderColor: 'dodgerblue'
    },
    buttonText: {
        paddingHorizontal: 15, 
        paddingVertical: 5, 
        borderRadius: 5, 
        borderWidth: 3, 
        borderColor: 'dodgerblue',
        // borderColor: 'blue',
        // backgroundColor: 'deepskyblue',
        fontWeight: 'bold',
        fontSize: hp('2%')
      }
})

export default FailedRSVP;