import React, {useEffect, useMemo, useState, useContext} from 'react';
import { View, Text, StyleSheet, Alert, Image, Dimensions, TextInput, Pressable } from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import { Context as CompanionsContext } from '../context/CompanionsContext';

const CompanionInputElement = ({orientation, height, width, index }) => {

    const { state: { companions }, updateCompanions } = useContext(CompanionsContext); 
    const [full_name, onChangeFullName] = React.useState(""); 

    const customFontFamily = "BelymonScriptDemo";
    const customBlue = 'rgb(75,107,214)';
        
    useEffect(() => {
        console.log({index})
        let name = [...companions];
        console.log("name", name)
        name[index] = full_name;
        console.log("new name", name)
        updateCompanions(name)
    }, [full_name])

    if(orientation == 'portrait') {
        return (
            <View>
                <TextInput
                        style={{
                            // height: height*0.03,
                            // width: width*0.3,
                            margin: height*0.005,
                            borderWidth: 2,
                            borderColor: customBlue,
                            fontSize: height*0.02,
                            fontFamily: customFontFamily
                        }}
                        onChangeText={onChangeFullName}
                        value={full_name}
                        placeholder='Naam en van'
                    />
            </View>
        )
    } else if (orientation == 'landscape') {
        return (
            <View>
                <TextInput
                        style={{
                            height: height*0.04,
                            width: width*0.18,
                            margin: height*0.005,
                            borderWidth: 2, 
                            borderColor: customBlue,
                            fontSize: height*0.025,
                            fontFamily: customFontFamily
                        }}
                        onChangeText={onChangeFullName}
                        value={full_name}
                        placeholder='Naam en van'
                    />
            </View>
        );
    }
};

const styles = StyleSheet.create({
    containerLandscape: {
        flex: 1, 
        flexDirection: 'row',
        justifyContent: 'center', 
        alignItems: 'center', 
        // borderWidth: 5,
        // borderColor: 'red',
    },
    containerPortrait: {
        flex: 1, 
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'center', 
        // borderWidth: 5,
        // borderColor: 'red',
    },
    graphicsViewLandscape: {
        flex: 1, 
        // height: Dimensions.get("window").height,
        flexDirection: 'row',
    },
    graphicsViewPortrait: {
        flex: 1, 
        // width: Dimensions.get("window").width,
        flexDirection: 'column',
        // borderWidth: 5,
        // borderColor: 'red',
    },
    borderStyle: {
        // borderWidth: 5,
        // borderColor: 'blue',
        // height: Dimensions.get("window").height,
        // width: Dimensions.get("window").height
        height: (Dimensions.get("window").width/4)*2,
        width: (Dimensions.get("window").width/4)*2,
    },

    sideImageStyle: {
        // height: Dimensions.get("window").height,
        // width: Dimensions.get("window").height/1.5,
        // height: (Dimensions.get("window").width/4)*1.5,
        // width: Dimensions.get("window").width/4,
        height: Dimensions.get("window").height,
        width: Dimensions.get("window").width/4,
        // borderWidth: 5,
        // borderColor: 'green',
    },
    overlayView: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textStyle: {
        // fontSize: 40,
        fontSize: hp('8%'),
        fontWeight: 'bold',
        color: 'dodgerblue'
    },
    input: {
        height: 40,
        margin: 12,
        borderWidth: 1,
      },
    inputResponsive: {
        height: hp('5%'),
        width: wp('18%'),
        margin: 12,
        borderWidth: 2,
        borderColor: 'dodgerblue'
    },
    buttonText: {
        paddingHorizontal: 15, 
        paddingVertical: 5, 
        borderRadius: 5, 
        borderWidth: 3, 
        borderColor: 'dodgerblue',
        // borderColor: 'blue',
        // backgroundColor: 'deepskyblue',
        fontWeight: 'bold',
        fontSize: hp('2%')
      }
})

export default CompanionInputElement;