import React, {useEffect, useMemo, useState} from 'react';
import { View, Text, StyleSheet, Alert, Image, Dimensions, TextInput, Pressable, ActivityIndicator } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import blouCenterPortrait from '../../images/Delft Blue/Blou 10.png'
import blouCenterLandscape from '../../images/Delft Blue/Blou 9.png'
import sideImage_1 from '../../images/Johan en Carma/Johan&Carma(71).jpg'
import topImage_1 from '../../images/Johan en Carma/Johan&Carma(104-crop).jpg'
import LoadingScreen from "../screens/LoadingScreen";
import { useFonts } from 'expo-font';
import { ScrollView } from 'react-native-gesture-handler';
// import MapView from 'react-native-web-maps';
import MapView from "react-native-web-google-maps";
import { AntDesign } from '@expo/vector-icons';


const GiftsScreen = ({navigation}) => {

    const customFontFamily = "BelymonScriptDemo";
    const customBlue = 'rgb(75,107,214)';

    const [imageLoaded, updateImageLoaded] = useState(false)

    const [orientation, updateOrientation] = useState('landscape');
    const [height, updateHeight] = useState(Dimensions.get('window').height);
    const [width, updateWidth] = useState(Dimensions.get('window').width);

    // Event Listener for orientation changes
    Dimensions.addEventListener('change', () => {
        console.log("addEventListener")
        updateHeight(Dimensions.get('window').height);
        updateWidth(Dimensions.get('window').width);
    });

    useMemo(() => {
        console.log("useMemo")
        if(width >= height) {
            updateOrientation('landscape')
        } else if(height >= width) {
            updateOrientation('portrait')
        }
        console.log(orientation)
    }, [width, height])

    useEffect(() => {
        console.log("useEffect")
        updateHeight(Dimensions.get('window').height);
        updateWidth(Dimensions.get('window').width);
        if(width >= height) {
            updateOrientation('landscape')
        } else if(height >= width) {
            updateOrientation('portrait')
        }
    }, [])

    useEffect(() => {
        updateImageLoaded(false)
    }, [orientation])

    if(orientation == 'portrait') {
        return (
            <SafeAreaView style={styles.containerPortrait}>
                {imageLoaded
                    ? null
                    : <View style={{
                        height: height,
                        width: width,
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        backgroundColor: 'white',
                        zIndex: 6
                    }}>
                        {/* <Image
                                    style={{
                                        height: height,
                                        width: width/2
                                    }}
                                    source={border_2}
                                /> */}
                        <View style={styles.overlayView}>
                            <ActivityIndicator size="large" color={customBlue} />
                            <Text style={{
                                textAlign: 'center',
                                marginTop: height*0.02,
                                fontFamily: customFontFamily,
                                fontSize: height*0.05,
                                fontWeight: 'bold',
                                color: customBlue
                            }}
                            >Wag 'n bietjie... #rossouwromanse laai</Text>
                        </View>
                    </View>}
                <View style={styles.graphicsViewPortrait}>
                    <View style={{
                        height: height,
                        width: width,
                        zIndex: 10,
                        justifyContent: 'center',
                        position: 'absolute',
                    }}>
                        <Image
                            style={{
                                height: height/1.6,
                                width: width
                            }}
                            source={blouCenterPortrait}
                        />
                    </View>
                    <Image
                        style={{
                            height: height/4,
                            width: width
                        }}
                        source={topImage_1}
                        onLoad={() => {updateImageLoaded(true)}}
                    />
                    <View style={{height: height/2,width: width}} />
                    <Image
                        style={{
                            height: height/4,
                            width: width
                        }}
                        source={topImage_1}
                    />
                </View>
                <View style={styles.overlayView}>
                    <Text
                        style={{
                            textAlign: 'center',
                            marginBottom: height*0.02,
                            color: customBlue,
                            fontFamily: customFontFamily,
                            fontSize: height*0.05,
                            fontWeight: 'bold'
                        }}>Oulike rympie om julle geld te vat</Text>
                </View>
            </SafeAreaView>
        );
    } else if (orientation == 'landscape') {
        return (
            <SafeAreaView style={styles.containerLandscape}>
                {imageLoaded
                    ? null
                    : <View style={{
                        height: height,
                        width: width,
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        backgroundColor: 'white',
                        zIndex: 6,
                    }}>
                        <View style={styles.overlayView}>
                            <ActivityIndicator size="large" color={customBlue} />
                            <Text style={{
                                textAlign: 'center',
                                marginTop: height*0.02,
                                fontFamily: customFontFamily,
                                fontSize: height*0.05,
                                fontWeight: 'bold',
                                color: customBlue
                            }}
                            >Wag 'n bietjie... #rossouwromanse laai</Text>
                        </View>
                    </View>}
                <View style={styles.graphicsViewLandscape}>
                    <View style={{
                        height: height,
                        width: width,
                        zIndex: 10,
                        alignItems: 'center',
                        position: 'absolute'
                    }}>
                        <Image
                            style={{
                                height: height,
                                width: width/1.6,
                                position: 'absolute',
                                resizeMode: 'stretch'
                            }}
                            source={blouCenterLandscape}
                        />
                    </View>
                    <View>
                        <Image
                            style={{
                                height: height,
                                width: width/4
                                // width: width/3.8
                            }}
                            source={sideImage_1}
                            onLoad={() => updateImageLoaded(true)}
                        />
                    </View>
                    <View style={{ height: height, width: width/2 }} />
                        <View>
                            {/*<Image*/}
                            {/*    style={{*/}
                            {/*        height: height,*/}
                            {/*        width: width/2*/}
                            {/*    }}*/}
                            {/*    source={blouCenterLandscape}*/}
                            {/*    onLoad={() => updateImageLoaded(true)}*/}
                            {/*/>*/}
                        </View>
                    <View>
                        <Image
                            style={{
                                height: height,
                                width: width/4
                                // width: width/3.8
                            }}
                            source={sideImage_1}
                        />
                    </View>
                </View>
                <View style={[styles.overlayView, {height: height, width: width/1.9}]}>
                    <AntDesign name="mail" size={250} color="black" />
                    <Text
                        style={{
                            textAlign: 'center',
                            // height: height*0.03,
                            marginBottom: height*0.04,
                            paddingLeft: width*0.015,
                            color: customBlue,
                            fontFamily: customFontFamily,
                            fontSize: height*0.09,
                            fontWeight: 'bold'
                        }}>#rossouwromanse</Text>
                </View>
            </SafeAreaView>
        );
    }
}

const styles = StyleSheet.create({
    containerLandscape: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        // borderWidth: 5,
        // borderColor: 'red',
    },
    containerPortrait: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // borderWidth: 5,
        // borderColor: 'red',
    },
    graphicsViewLandscape: {
        flex: 1,
        // height: Dimensions.get("window").height,
        flexDirection: 'row',
    },
    graphicsViewPortrait: {
        flex: 1,
        // width: Dimensions.get("window").width,
        flexDirection: 'column',
        // borderWidth: 5,
        // borderColor: 'red',
    },
    borderStyle: {
        // borderWidth: 5,
        // borderColor: 'blue',
        // height: Dimensions.get("window").height,
        // width: Dimensions.get("window").height
        height: (Dimensions.get("window").width/4)*2,
        width: (Dimensions.get("window").width/4)*2,
    },

    sideImageStyle: {
        // height: Dimensions.get("window").height,
        // width: Dimensions.get("window").height/1.5,
        // height: (Dimensions.get("window").width/4)*1.5,
        // width: Dimensions.get("window").width/4,
        height: Dimensions.get("window").height,
        width: Dimensions.get("window").width/4,
        // borderWidth: 5,
        // borderColor: 'green',
    },
    overlayView: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textStyle: {
        // fontSize: 40,
        fontSize: hp('8%'),
        fontWeight: 'bold',
        color: 'dodgerblue'
    },
    input: {
        height: 40,
        margin: 12,
        borderWidth: 1,
    },
    inputResponsive: {
        height: hp('5%'),
        width: wp('18%'),
        margin: 12,
        borderWidth: 2,
        borderColor: 'dodgerblue'
    },
    buttonText: {
        paddingHorizontal: 15,
        paddingVertical: 5,
        borderRadius: 5,
        borderWidth: 3,
        borderColor: 'dodgerblue',
        // borderColor: 'blue',
        // backgroundColor: 'deepskyblue',
        fontWeight: 'bold',
        fontSize: hp('2%')
    }
})

export default GiftsScreen;
