import createDataContext from './createDataContext';

const companionsReducer = (state, action) => {
    switch (action.type) {
        case 'update_companions':
            return { ...state, companions: action.payload };
    
        default:
            state;
    }
};

const updateCompanions = dispatch => async (companioinsArray) => {
    await dispatch({ type: 'update_companions', payload: companioinsArray });
};

export const { Provider, Context } = createDataContext(
    companionsReducer,
    {updateCompanions},
    {companions: []}
);