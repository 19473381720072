import React, {useEffect, useMemo, useState} from 'react';
import { View, Text, StyleSheet, Alert, Image, Dimensions, TextInput, Pressable, ActivityIndicator } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import blouCenterPortrait from '../../images/Delft Blue/Blou 10.png'
import blouCenterLandscape from '../../images/Delft Blue/Blou 9.png'
import sideImage_1 from '../../images/Johan en Carma/Johan&Carma(71).jpg'
import topImage_1 from '../../images/Johan en Carma/Johan&Carma(104-crop).jpg'
import LoadingScreen from "../screens/LoadingScreen";
import { useFonts } from 'expo-font';
import moment from "moment";

const SaveTheDateScreen = ({navigation}) => {

    let [fontsLoaded] = useFonts({
        'KarmilaScript': require('../../assets/fonts/KarmilaScript-JRJ4j.otf'),
        'HuntingScript': require('../../assets/fonts/HuntingScript-ZVgBZ.otf'),
        'GrettanaBold': require('../../assets/fonts/GrettanaBold-GOnBm.ttf'),
        'AchelanScript': require('../../assets/fonts/AchelanScript-X3Md9.ttf'),
        'BlankenyScript': require('../../assets/fonts/BlankenyScript-vmyXM.otf'),
        'ReginaScript': require('../../assets/fonts/ReginaScript-mL1WG.otf'),
        'MagtinaScript': require('../../assets/fonts/MagtinaScript-PKqgE.otf'),
        'MargarethScript': require('../../assets/fonts/MargarethScript-1GjrB.otf'),
        'MarvelousScriptDemo': require('../../assets/fonts/MarvelousScriptDemo-7lGV.otf'),
        'SaturdateScript': require('../../assets/fonts/SaturdateScript-9gm5.ttf'),
        'BelymonScriptDemo': require('../../assets/fonts/BelymonScriptDemo-512Px.otf'),
    });

    const customFontFamily = "BelymonScriptDemo";
    const customBlue = 'rgb(75,107,214)';
    const weddingDate = new Date(2022,3, 23, 15, 0, 0);

    const [imageLoaded, updateImageLoaded] = useState(false)

    const [orientation, updateOrientation] = useState('landscape');
    const [height, updateHeight] = useState(Dimensions.get('window').height);
    const [width, updateWidth] = useState(Dimensions.get('window').width);
    const [countDownTime, updateCountDownTime] = useState('');

    function displayTime() {
        let date = new Date();

        let diff = weddingDate.getTime() - date.getTime()
        let diff_days = Math.floor(diff/(1000*3600*24))
        let diff_hours = Math.floor(diff/(1000*3600)-diff_days*24)
        let diff_minutes = Math.ceil(diff/(1000*60)-diff_days*24*60-diff_hours*60)
        // let time = date.toLocaleTimeString();
        updateCountDownTime(`${diff_days} dae, ${diff_hours} ure and ${diff_minutes} minute`)
        // updateCountDownTime( weddingDate.getHours() )
        // days: date.getDate()
        // month: date.getMonth()+1
        // year: date.getFullYear()
    }

    const createClock = setInterval(displayTime, 1000);

    // Event Listener for orientation changes
    Dimensions.addEventListener('change', () => {
        console.log("addEventListener")
        updateHeight(Dimensions.get('window').height);
        updateWidth(Dimensions.get('window').width);
    });

    useMemo(() => {
        console.log("useMemo")
        if(width >= height) {
            updateOrientation('landscape')
        } else if(height >= width) {
            updateOrientation('portrait')
        }
        console.log(orientation)
    }, [width, height])

    useEffect(() => {
        console.log("useEffect")
        updateHeight(Dimensions.get('window').height);
        updateWidth(Dimensions.get('window').width);
        if(width >= height) {
            updateOrientation('landscape')
        } else if(height >= width) {
            updateOrientation('portrait')
        }
    }, [])

    useEffect(() => {
        updateImageLoaded(false)
    }, [orientation])

    if (!fontsLoaded) {
        return (
            <LoadingScreen />
        );
    } else {
        if(orientation == 'portrait') {
            return (
                <SafeAreaView style={styles.containerPortrait}>
                    {imageLoaded
                        ? null
                        : <View style={{
                            height: height,
                            width: width,
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'absolute',
                            backgroundColor: 'white',
                            zIndex: 6
                        }}>
                            {/* <Image
                                    style={{
                                        height: height,
                                        width: width/2
                                    }}
                                    source={border_2}
                                /> */}
                            <View style={styles.overlayView}>
                                <ActivityIndicator size="large" color={customBlue} />
                                <Text style={{
                                    textAlign: 'center',
                                    marginTop: height*0.02,
                                    fontFamily: customFontFamily,
                                    fontSize: height*0.05,
                                    fontWeight: 'bold',
                                    color: customBlue
                                }}
                                >Wag 'n bietjie... #rossouwromanse laai</Text>
                            </View>
                        </View>}
                    <View style={styles.graphicsViewPortrait}>
                        <View style={{
                            height: height,
                            width: width,
                            zIndex: 10,
                            justifyContent: 'center',
                            position: 'absolute',
                        }}>
                            <Image
                                style={{
                                    height: height/1.6,
                                    width: width
                                }}
                                source={blouCenterPortrait}
                            />
                        </View>
                        <Image
                            style={{
                                height: height/4,
                                width: width
                            }}
                            source={topImage_1}
                            onLoad={() => {updateImageLoaded(true)}}
                        />
                        <View style={{height: height/2,width: width}} />
                        <Image
                            style={{
                                height: height/4,
                                width: width
                            }}
                            source={topImage_1}
                        />
                    </View>
                    <View style={[styles.overlayView, {marginTop: height*0.1}]}>
                        <Text
                            style={{
                                textAlign: 'center',
                                // marginBottom: height*0.005,
                                color: customBlue,
                                fontFamily: customFontFamily,
                                fontSize: height*0.05,
                                fontWeight: 'bold'
                            }}>#rossouwromanse</Text>
                        <Text
                            style={{
                                textAlign: 'center',
                                // margin: height*0.005,
                                color: customBlue,
                                fontFamily: customFontFamily,
                                fontSize: height*0.025,
                                fontWeight: 'bold'
                            }}>{countDownTime}</Text>
                        <Pressable
                            style={({ pressed }) => [
                                {
                                    backgroundColor: pressed
                                        ? 'rgb(210, 230, 255)'
                                        : 'white',
                                    borderRadius: 5,
                                    borderWidth: 3,
                                    borderColor: customBlue,
                                    // marginTop: height*0.01,
                                    marginTop: height*0.06,
                                    // backgroundColor: 'rgba(75,107,214,0.2)'
                                }
                            ]}
                            onPress={() => {
                                navigation.navigate("RSVP")
                            }}
                        >
                            <View style={{alignItems: 'center', margin: height*0.005}}>
                                <Text
                                    style={{
                                        // paddingHorizontal: width*0.003,
                                        // paddingVertical: height*0.0005,
                                        fontFamily: customFontFamily,
                                        fontWeight: 'bold',
                                        fontSize: height*0.015,
                                        color: customBlue,
                                    }}
                                >RSVP</Text>
                            </View>
                        </Pressable>
                        {/*<Text*/}
                        {/*    style={{*/}
                        {/*        textAlign: 'center',*/}
                        {/*        // margin: height*0.005,*/}
                        {/*        color: customBlue,*/}
                        {/*        fontFamily: customFontFamily,*/}
                        {/*        fontSize: height*0.025,*/}
                        {/*        fontWeight: 'bold',*/}
                        {/*        marginTop: height*0.01,*/}
                        {/*    }}>RSVP teen 31 Januarie 2022</Text>*/}
                    </View>
                </SafeAreaView>
            );
        } else if (orientation == 'landscape') {
            return (
                <SafeAreaView style={styles.containerLandscape}>
                    {imageLoaded
                        ? null
                        : <View style={{
                            height: height,
                            width: width,
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'absolute',
                            backgroundColor: 'white',
                            zIndex: 6,
                        }}>
                            <View style={styles.overlayView}>
                                <ActivityIndicator size="large" color={customBlue} />
                                <Text style={{
                                    textAlign: 'center',
                                    marginTop: height*0.02,
                                    fontFamily: customFontFamily,
                                    fontSize: height*0.05,
                                    fontWeight: 'bold',
                                    color: customBlue
                                }}
                                >Wag 'n bietjie... #rossouwromanse laai</Text>
                            </View>
                        </View>}
                    <View style={styles.graphicsViewLandscape}>
                        <View style={{
                            height: height,
                            width: width,
                            zIndex: 10,
                            alignItems: 'center',
                            position: 'absolute'
                        }}>
                            <Image
                                style={{
                                    height: height,
                                    width: width/1.6,
                                    position: 'absolute',
                                    resizeMode: 'stretch'
                                }}
                                source={blouCenterLandscape}
                            />
                        </View>
                        <View>
                            <Image
                                style={{
                                    height: height,
                                    width: width/4
                                    // width: width/3.8
                                }}
                                source={sideImage_1}
                                onLoad={() => updateImageLoaded(true)}
                            />
                        </View>
                        <View style={{ height: height, width: width/2 }} />
                        {/* <View>
                                <Image
                                    style={{
                                        height: height,
                                        width: width/2
                                    }}
                                    source={blou_3}
                                    onLoad={() => updateImageLoaded(true)}
                                />
                            </View> */}
                        <View>
                            <Image
                                style={{
                                    height: height,
                                    width: width/4
                                    // width: width/3.8
                                }}
                                source={sideImage_1}
                            />
                        </View>
                    </View>
                    <View style={styles.overlayView}>
                        <Text
                            style={{
                                textAlign: 'center',
                                // height: height*0.03,
                                marginBottom: height*0.04,
                                paddingLeft: width*0.015,
                                color: customBlue,
                                fontFamily: customFontFamily,
                                fontSize: height*0.09,
                                fontWeight: 'bold'
                            }}>#rossouwromanse</Text>
                        {/*<Text*/}
                        {/*    style={{*/}
                        {/*        textAlign: 'center',*/}
                        {/*        // height: height*0.03,*/}
                        {/*        width: width*0.3,*/}
                        {/*        margin: height*0.01,*/}
                        {/*        color: customBlue,*/}
                        {/*        fontFamily: customFontFamily,*/}
                        {/*        fontSize: height*0.05,*/}
                        {/*        fontWeight: 'bold'*/}
                        {/*    }}>23 April 2022</Text>*/}
                        {/*<Text*/}
                        {/*    style={{*/}
                        {/*        textAlign: 'center',*/}
                        {/*        // height: height*0.05,*/}
                        {/*        width: width*0.3,*/}
                        {/*        margin: height*0.01,*/}
                        {/*        color: customBlue,*/}
                        {/*        fontFamily: customFontFamily,*/}
                        {/*        fontSize: height*0.05,*/}
                        {/*        fontWeight: 'bold'*/}
                        {/*    }}>Nooitgedacht, Stellenbosch</Text>*/}
                        {/*<Text*/}
                        {/*    style={{*/}
                        {/*        textAlign: 'center',*/}
                        {/*        // height: height*0.05,*/}
                        {/*        width: width*0.3,*/}
                        {/*        margin: height*0.01,*/}
                        {/*        color: customBlue,*/}
                        {/*        fontFamily: customFontFamily,*/}
                        {/*        fontSize: height*0.05,*/}
                        {/*        fontWeight: 'bold'*/}
                        {/*    }}>{moment(weddingDate).format("dddd, DD MMMM, MM YYYY")}</Text>*/}
                        <Text
                            style={{
                                textAlign: 'center',
                                // height: height*0.05,
                                width: width*0.3,
                                margin: height*0.01,
                                color: customBlue,
                                fontFamily: customFontFamily,
                                fontSize: height*0.05,
                                fontWeight: 'bold'
                            }}>{countDownTime}</Text>
                        {/*<View style={{*/}
                        {/*    marginTop: height*0.06,*/}
                        {/*    borderTopWidth: 5,*/}
                        {/*    borderRightWidth: 5,*/}
                        {/*    borderRadius: 4,*/}
                        {/*    borderColor: 'rgb(0,0,0,1)'*/}
                        {/*}}>*/}
                            <Pressable
                                style={({ pressed }) => [
                                    {
                                        backgroundColor: pressed
                                            ? 'rgb(210, 230, 255)'
                                            : 'rgb(255,255,255,0)',
                                        borderRadius: 5,
                                        borderWidth: 3,
                                        borderColor: customBlue,
                                        // marginTop: height*0.01,
                                        marginTop: height*0.06,
                                        // backgroundColor: 'rgba(75,107,214,0.2)'
                                    }
                                ]}
                                onPress={() => {
                                    navigation.navigate("RSVP")
                                }}
                            >
                                <View style={{alignItems: 'center', margin: height*0.005}}>
                                    <Text
                                        style={{
                                            // paddingHorizontal: width*0.003,
                                            // paddingVertical: height*0.0005,
                                            fontFamily: customFontFamily,
                                            fontWeight: 'bold',
                                            fontSize: height*0.03,
                                            color: customBlue,
                                        }}
                                    >RSVP</Text>
                                </View>
                            </Pressable>
                        {/*</View>*/}
                        {/*<Text*/}
                        {/*    style={{*/}
                        {/*        textAlign: 'center',*/}
                        {/*        // height: height*0.05,*/}
                        {/*        width: width*0.3,*/}
                        {/*        margin: height*0.01,*/}
                        {/*        color: customBlue,*/}
                        {/*        fontFamily: customFontFamily,*/}
                        {/*        fontSize: height*0.05,*/}
                        {/*        fontWeight: 'bold'*/}
                        {/*    }}>RSVP teen 31 Januarie 2022</Text>*/}
                    </View>
                </SafeAreaView>
            );
        }
    }
}

const styles = StyleSheet.create({
    containerLandscape: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        // borderWidth: 5,
        // borderColor: 'red',
    },
    containerPortrait: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // borderWidth: 5,
        // borderColor: 'red',
    },
    graphicsViewLandscape: {
        flex: 1,
        // height: Dimensions.get("window").height,
        flexDirection: 'row',
    },
    graphicsViewPortrait: {
        flex: 1,
        // width: Dimensions.get("window").width,
        flexDirection: 'column',
        // borderWidth: 5,
        // borderColor: 'red',
    },
    borderStyle: {
        // borderWidth: 5,
        // borderColor: 'blue',
        // height: Dimensions.get("window").height,
        // width: Dimensions.get("window").height
        height: (Dimensions.get("window").width/4)*2,
        width: (Dimensions.get("window").width/4)*2,
    },

    sideImageStyle: {
        // height: Dimensions.get("window").height,
        // width: Dimensions.get("window").height/1.5,
        // height: (Dimensions.get("window").width/4)*1.5,
        // width: Dimensions.get("window").width/4,
        height: Dimensions.get("window").height,
        width: Dimensions.get("window").width/4,
        // borderWidth: 5,
        // borderColor: 'green',
    },
    overlayView: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textStyle: {
        // fontSize: 40,
        fontSize: hp('8%'),
        fontWeight: 'bold',
        color: 'dodgerblue'
    },
    input: {
        height: 40,
        margin: 12,
        borderWidth: 1,
    },
    inputResponsive: {
        height: hp('5%'),
        width: wp('18%'),
        margin: 12,
        borderWidth: 2,
        borderColor: 'dodgerblue'
    },
    buttonText: {
        paddingHorizontal: 15,
        paddingVertical: 5,
        borderRadius: 5,
        borderWidth: 3,
        borderColor: 'dodgerblue',
        // borderColor: 'blue',
        // backgroundColor: 'deepskyblue',
        fontWeight: 'bold',
        fontSize: hp('2%')
    }
})

export default SaveTheDateScreen;
