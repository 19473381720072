import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import SaveTheDateScreen from './src/screens/SaveTheDate'
import RSVPScreen from './src/screens/RSVP';
import SuccessfulRSVP from './src/screens/SuccessfulRSVP';
import HomeScreen from './src/screens/Home';
import FailedRSVP from './src/screens/FailedRSVP';
import GiftsScreen from "./src/screens/Gifts";
import PhotosScreen from "./src/screens/Photos";
import DetailsScreen from "./src/screens/Details";
import AccommodationScreen from "./src/screens/Accommodation";

import { Provider as CompanionsProvider } from './src/context/CompanionsContext';

import { Ionicons } from '@expo/vector-icons'; 
import { FontAwesome5 } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
import { Entypo } from '@expo/vector-icons';

// import tile_2 from '../images/Delft Blue/Tile 2.jpg';
// import tile_3 from '../images/Delft Blue/Tile 3.jpg';
// import tile_4 from '../images/Delft Blue/Tile 4.jpg';
// import tile_5 from '../images/Delft Blue/Tile 5.jpg';

const RSVPStack = createStackNavigator();
const RSVPStackScreen = () => (
  <RSVPStack.Navigator 
    initialRouteName="RSVP"
    headerMode="none"
    tabBarOptions={{
      activeBackgroundColor: 'dodgerblue',
      activeTintColor: "white",
      inactiveBackgroundColor: 'white',
      inactiveTintColor: "black",
      lazy: false,
      style: {
        backgroundColor: 'white',
          // height: Dimensions.get('window').height*0.085,
          height: 60,
          // borderRadius: 10,
          // marginHorizontal: 20,
          // elevation: 5
          // height: 40
      },
      tabStyle: {
          borderRadius: 10,
          // margin: 5,
          // elevation: 5,
          marginHorizontal: 100
      },
      tabBarLabel: {
        // padding: 5
      },
      labelStyle: {
          fontSize: 16,
          fontWeight: 'bold',
          fontFamily: 'normal',
          // paddingBottom: Dimensions.get('window').height*0.008
          paddingBottom: 5,
      },
      labelPosition: 'below-icon'
  }}
  >
        <RSVPStack.Screen
          name="SaveTheDate"
          component={SaveTheDateScreen}
          options={{
            animationEnabled: false,
            tabBarLabel: 'Save The Date',
            tabBarIcon: ({color, size}) => (
              // <View style={{
              //   justifyContent: 'center',
              //   alignItems: 'center',
              //   backgroundColor: 'lightskyblue',
              //   height: 130,
              //   width:130,
              //   borderWidth: 5,
              //   borderColor: 'blue'
              // }}>
              <Entypo name="flower" size={30} color={color} />
                // <Ionicons name="md-flower-outline" size={50} color={color} />
              // </View>
            )
          }}
        />
        <RSVPStack.Screen
          name="RSVP"
          component={RSVPScreen}
          options={{
            animationEnabled: false,
            tabBarLabel: 'RSVP',
            tabBarIcon: ({color, size}) => (
              <Text>💎💍</Text>
              // <FontAwesome5 name="clipboard-check" size={24} color="black" />            
            )
          }}
        />
        <RSVPStack.Screen
          name="Successful RSVP"
          component={SuccessfulRSVP}
          options={{
            animationEnabled: false,
          }}
        />
        <RSVPStack.Screen
          name="Failed RSVP"
          component={FailedRSVP}
          options={{
            animationEnabled: false,
          }}
        />
  </RSVPStack.Navigator>
);

const RootStack = createBottomTabNavigator();
const RootStackScreen = () => (

  <RootStack.Navigator
      initialRouteName="Tuisblad"
      // initialRouteName="Besonderhede",
      tabBarOptions={{
          activeBackgroundColor: 'rgb(75,107,214)',
          activeTintColor: "white",
          inactiveBackgroundColor: 'white',
          inactiveTintColor: 'rgb(75,107,214)',
      }}
  >
    <RSVPStack.Screen
      name="Besonderhede"
      component={DetailsScreen}
      options= {{
        animationEnabled: false,
          tabBarIcon: ({color, size}) => <Ionicons name="flower" size={30} color={color} />
        // tabBarIcon: ({color, size}) => <Entypo name="flower" size={35} color='rgb(75,107,214)' />
        // <Feather name="heart" size={50} color={color} />
      }}
    />
      <RSVPStack.Screen
          name="Tuisblad"
          component={HomeScreen}
          options= {{
              animationEnabled: false,
              tabBarIcon: ({color, size}) => <Ionicons name="flower" size={30} color={color} />
              // tabBarIcon: ({color, size}) => <Entypo name="flower" size={50} color={color} />
              // <Feather name="heart" size={50} color={color} />
          }}
      />
    <RSVPStack.Screen
      name="RSVP"
      component={RSVPStackScreen}
      options= {{
        animationEnabled: false,
          tabBarIcon: ({color, size}) => <Ionicons name="flower" size={30} color={color} />
        // tabBarIcon: ({color, size}) => <Entypo name="flower" size={50} color={color} />
        // <Feather name="heart" size={50} color={color} />
      }}
    />
      {/*<RSVPStack.Screen*/}
      {/*    name="Verblyf"*/}
      {/*    component={AccommodationScreen}*/}
      {/*    options= {{*/}
      {/*        animationEnabled: false,*/}
      {/*        tabBarIcon: ({color, size}) => <Ionicons name="flower" size={30} color={color} />*/}
      {/*        // tabBarIcon: ({color, size}) => <Entypo name="flower" size={50} color={color} />*/}
      {/*        // <Feather name="heart" size={50} color={color} />*/}
      {/*    }}*/}
      {/*/>*/}
      {/*<RSVPStack.Screen*/}
      {/*    name="Geskenke Idees"*/}
      {/*    component={GiftsScreen}*/}
      {/*    options= {{*/}
      {/*        animationEnabled: false,*/}
      {/*        // tabBarIcon: ({color, size}) => <Entypo name="flower" size={50} color={color} />*/}
      {/*        // <Feather name="heart" size={50} color={color} />*/}
      {/*    }}*/}
      {/*/>*/}
      {/*<RSVPStack.Screen*/}
      {/*    name="Liedjies & Kiekies"*/}
      {/*    component={PhotosScreen}*/}
      {/*    options= {{*/}
      {/*        animationEnabled: false,*/}
      {/*        tabBarIcon: ({color, size}) => <Ionicons name="flower" size={30} color={color} />*/}
      {/*        // tabBarIcon: ({color, size}) => <Entypo name="flower" size={50} color={color} />*/}
      {/*        // <Feather name="heart" size={50} color={color} />*/}
      {/*    }}*/}
      {/*/>*/}
  </RootStack.Navigator>
)

export default function App() {
  return (
    <NavigationContainer>
      <CompanionsProvider>
        <RootStackScreen />
      </CompanionsProvider>
    </NavigationContainer>
  );
}
