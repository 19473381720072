import React, {useEffect, useMemo, useState, useContext} from 'react';
import { View, Text, StyleSheet, Image, Dimensions, TextInput, Pressable, ActivityIndicator } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import sideImage_1 from '../../images/Johan en Carma/Johan&Carma(65).jpg'
import blouCenter from '../../images/Delft Blue/Blou_5.png'
// import topImage_1 from '../../images/Johan en Carma/Johan&Carma(104-crop).jpg'
import topImage_1 from '../../images/Johan en Carma/Johan&Carma(90).jpg'
import axios from 'axios';
import {Picker} from '@react-native-picker/picker';
import { Feather } from '@expo/vector-icons'; 
import CompanionInputElement from'../components/CompanionsInputElement';
import { Context as CompanionsContext } from '../context/CompanionsContext';
import { ScrollView } from 'react-native';
import troueAPI from '../api/troueAPI';

const RSVPScreen = ({navigation}) => {

    /**
     * To Do:
     *  - Loading/Please Wait screen when information is submitted
     *  - Screen to shown result from API if information is successsfully submitted or not
     *  - Button with functionality to navigate to screen to change information
     */

    const customFontFamily = "BelymonScriptDemo";
    const customBlue = 'rgb(75,107,214)';

    const [imageLoaded, updateImageLoaded] = useState(false)

    const [orientation, updateOrientation] = useState('landscape');
    const [height, updateHeight] = useState(Dimensions.get('window').height);
    const [width, updateWidth] = useState(Dimensions.get('window').width);
    const [requestProgress, updateRequestProgress] = useState(false);

    const [full_name, onChangeFullName] = React.useState(""); 
    const [first_name, onChangeFirstName] = React.useState(""); 
    const [last_name, onChangeLastName] = React.useState("");
    const [phone, onChangePhone] = React.useState(""); 
    const [email, onChangeEmail] = React.useState(""); 
    const [status, onChangeStatus] = React.useState("Yes");
    const [diet, onChangeDiet] = React.useState("");
    
    const [companionsArray, updateCompanionsArray] = React.useState([]); 
    const { state: { companions }, updateCompanions } = useContext(CompanionsContext); 
    // const [companions, updateCompanions] = React.useState([]); 

    // const companionArray = ["Element 1", "Element 2"]

    // Event Listener for orientation changes
    Dimensions.addEventListener('change', () => {
        console.log("addEventListener")
        updateHeight(Dimensions.get('window').height);
        updateWidth(Dimensions.get('window').width);
    });

    useMemo(() => {
        console.log("useMemo")
        if(width >= height) {
            updateOrientation('landscape')
        } else if(height >= width) {
            updateOrientation('portrait')
        }
        console.log(orientation)
    }, [width, height])

    useEffect(() => {
        console.log("useEffect")
        updateHeight(Dimensions.get('window').height);
        updateWidth(Dimensions.get('window').width);
        if(width >= height) {
            updateOrientation('landscape')
        } else if(height >= width) {
            updateOrientation('portrait')
        }
    }, [])

    useEffect(() => {
        updateImageLoaded(false)
    }, [orientation])

    console.log("companionsArray.length", companionsArray.length)
    console.log("companions", companions)
    const submitFunction = async () => {
        // console.log("Executing submit function")
       
        try {
            // const response = await troueAPI.post('/rsvp', { first_name, last_name, phone, email });
            // axios.post('http://192.168.1.253:3000/rsvp', {first_name, last_name, phone, email})
            // axios.get("http://192.168.1.253:3000/")

            const response = await troueAPI.post('/rsvp',{
                full_name, 
                phone, 
                email,
                status,
                companions,
                diet
            })

            // const response = await axios({
            //     method: 'post',
            //     url: 'https://111e-196-223-141-205.ngrok.io/rsvp',
            //     data: {
            //         full_name, 
            //         phone, 
            //         email,
            //         status,
            //         companions,
            //         diet
            //     }
            // });

            console.log("\n", "Response.data")
            console.log(response.data)
            onChangeFirstName(response.data.status)

            if(response.data === 'Successful') {
                updateRequestProgress(false)
                navigation.navigate("Successful RSVP")
            } else {
                updateRequestProgress(false)
                navigation.navigate("Failed RSVP")
            }
        } catch (error) {
            console.log(error.message);
        }
    }


    if(orientation == 'portrait') {
        return (
            <View style={styles.containerPortrait}>
                {imageLoaded
                ? null
                : <View style={{
                        height: height, 
                        width: width, 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        position: 'absolute', 
                        backgroundColor: 'white', 
                        zIndex: 6
                    }}>
                        {/* <Image 
                            style={{
                                height: height,
                                width: width/2
                            }}
                            source={border_2}
                        /> */}
                        <View style={styles.overlayView}>
                        <ActivityIndicator size="large" color={customBlue} />
                            <Text style={{
                                    textAlign: 'center', 
                                    marginTop: height*0.02,
                                    fontFamily: customFontFamily,
                                    fontSize: height*0.05,
                                    fontWeight: 'bold',
                                    color: customBlue
                                }}
                            >Wag 'n bietjie... #rossouwromanse laai</Text>
                        </View>
                </View>}
                <View style={styles.graphicsViewPortrait}>
                    <Image
                        style={{
                            height: height/4,
                            width: width
                        }}
                        source={topImage_1}
                        onLoad={() => {updateImageLoaded(true)}}
                    />
                    <Image
                        style={{
                            height: height/2,
                            width: width
                        }}
                        source={blouCenter}
                    />
                    <Image
                        style={{
                            height: height/4,
                            width: width
                        }}
                        source={topImage_1}
                    />
                </View>
                {requestProgress
                ? <View style={styles.overlayView}><ActivityIndicator size="large" color="#0000ff" /></View>
                : <View style={styles.overlayView}>
                    {/* <Text style={{
                        fontSize: height*0.04,
                        fontWeight: 'bold',
                        color: 'dodgerblue'
                    }}>RSVP</Text> */}
                    {/* <Text>{orientation}</Text>
                    <Text>Height: {round(height)}</Text>
                    <Text>Width: {round(width)}</Text> */}
                    <TextInput
                        style={{
                            marginTop: height*0.1,
                            // height: height*0.04,
                            width: width*0.5,
                            margin: height*0.005,
                            borderWidth: 2,
                            borderColor: customBlue,
                            fontFamily: customFontFamily,
                            fontSize: height*0.02,
                            placeholderTextColor: customBlue
                        }}
                        onChangeText={onChangeFullName}
                        value={full_name}
                        placeholder='Naam en van*'
                    />
                    {/* <TextInput
                        style={{
                            // height: height*0.04,
                            width: width*0.5,
                            margin: height*0.005,
                            borderWidth: 2,
                            borderColor: customBlue,
                            fontFamily: customFontFamily,
                            fontSize: height*0.02
                        }}
                        onChangeText={onChangeLastName}
                        value={last_name}
                        placeholder='Van*'
                    /> */}
                    <TextInput
                        style={{
                            // height: height*0.04,
                            width: width*0.5,
                            margin: height*0.005,
                            borderWidth: 2,
                            borderColor: customBlue,
                            fontFamily: customFontFamily,
                            fontSize: height*0.02,
                            placeholderTextColor: customBlue
                        }}
                        onChangeText={onChangePhone}
                        value={phone}
                        placeholder='Kontaknommer*'
                    />
                    <TextInput
                        style={{
                            // height: height*0.04,
                            width: width*0.5,
                            margin: height*0.005,
                            borderWidth: 2,
                            borderColor: customBlue,
                            fontFamily: customFontFamily,
                            fontSize: height*0.02,
                            placeholderTextColor: customBlue
                        }}
                        onChangeText={onChangeEmail}
                        value={email}
                        placeholder='E-pos*'
                    />
                    <View style={{flexDirection: 'row', width: width*0.5, justifyContent: 'space-between', alignContent: 'center'}}>
                        <Text style={{
                            // height: height*0.05,
                            paddingTop: height*0.004,
                            // marginHorizontal: width*0.02,
                            // width: width*0.18,
                            // borderWidth: 2,
                            // borderColor: 'dodgerblue',
                            color: customBlue,
                            fontFamily: customFontFamily,
                            fontSize: height*0.02
                        }}>Sien ons jou daar?</Text>
                        <Picker
                            selectedValue={status}
                            onValueChange={(itemValue, itemIndex) => onChangeStatus(itemValue)}
                            style={{
                                height: height*0.03,
                                // padding: height*0.01,
                                // marginHorizontal: 10,
                                color: customBlue,
                                fontFamily: customFontFamily,
                                borderWidth: 2,
                                borderColor: customBlue,
                                marginVertical: height*0.005,
                                // fontSize: height*0.01
                            }}
                        >
                            <Picker.Item label="Ja" value="Yes" />
                            <Picker.Item label="Nee" value="No" />
                            <Picker.Item label="Miskien" value="Maybe" />
                        </Picker>
                    </View>
                    {/* <Text style={{
                        height: height*0.05,
                        // marginHorizontal: width*0.02,
                        // width: width*0.18,
                        // borderWidth: 2,
                        // borderColor: 'dodgerblue',
                        fontSize: height*0.03,
                        fontFamily: customFontFamily,
                    }}>Dieetvoorkeure?</Text> */}
                    <TextInput
                        style={{
                            // height: height*0.04,
                            width: width*0.5,
                            margin: height*0.002,
                            borderWidth: 2,
                            borderColor: customBlue,
                            fontFamily: customFontFamily,
                            fontSize: height*0.02,
                            placeholderTextColor: customBlue
                        }}
                        onChangeText={onChangeDiet}
                        value={diet}
                        placeholder='Dieetvoorkeure?*'
                    />
                    <Text style={{
                        // height: height*0.04,
                        // marginHorizontal: width*0.02,
                        // width: width*0.18,
                        // borderWidth: 2,
                        // borderColor: 'dodgerblue',
                        marginTop: height*0.005,
                        borderColor: customBlue,
                        color: customBlue,
                        fontFamily: customFontFamily,
                        fontSize: height*0.018
                    }}>Wie is jou metgesel? (indien van toepassing)</Text>
                    <Pressable
                        style={{alignSelf: 'center'}}
                        onPress={() => {
                            updateCompanionsArray([...companionsArray, 
                                <CompanionInputElement 
                                    key={companionsArray.length}
                                    orientation={orientation}  
                                    height={height}
                                    width={width}
                                    index={companionsArray.length}
                                />
                            ])
                        }}
                    >
                        <View>
                            <Feather name="user-plus" size={20} color={customBlue} />
                        </View>
                    </Pressable>
                    <ScrollView style={{height: height*0.05}}>
                        {companionsArray.map(elem => elem)}
                    </ScrollView>
                    <Pressable
                        style={({ pressed }) => [
                            {
                                backgroundColor: pressed
                                ? 'rgb(210, 230, 255)'
                                : 'white',
                                borderRadius: 5, 
                                borderWidth: 3, 
                                borderColor: customBlue,
                                marginTop: height*0.04
                            }
                        ]}
                        onPress={() => {

                            if(
                                full_name == "" || full_name == null 
                                || phone == "" || phone == null
                                || email == "" || email == null
                            ) {
                                alert("Please complete all fields!")
                            } else {
                                updateRequestProgress(true)
                                submitFunction();
                            }
                        }}
                    >
                        <View style={{alignItems: 'center', margin: height*0.005}}>
                            <Text 
                                style={{
                                    // paddingHorizontal: width*0.005, 
                                    // paddingVertical: height*0.002, 
                                    // borderRadius: 5, 
                                    // borderWidth: 3, 
                                    // borderColor: 'dodgerblue',
                                    // borderColor: 'blue',
                                    // backgroundColor: 'deepskyblue',
                                    color: customBlue,
                                    fontFamily: customFontFamily,
                                    fontWeight: 'bold',
                                    fontSize: height*0.02
                                }}
                            >Bevestig</Text>
                        </View>
                    </Pressable>
                </View>
                }

            </View>
        );
    } else if (orientation == 'landscape') {
        return (
            <View style={styles.containerLandscape}>
                {imageLoaded
                ? null
                : <View style={{
                        height: height,
                        width: width, 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        position: 'absolute', 
                        backgroundColor: 'white', 
                        zIndex: 6,
                    }}>
                        {/* <Image 
                            style={{
                                height: height,
                                width: width/2
                            }}
                            source={border_2}
                        /> */}
                        <View style={styles.overlayView}>
                            <ActivityIndicator size="large" color={customBlue} />
                            <Text style={{
                                    textAlign: 'center', 
                                    marginTop: height*0.02,
                                    fontFamily: customFontFamily,
                                    fontSize: height*0.05,
                                    fontWeight: 'bold',
                                    color: customBlue
                                }}
                            >Wag 'n bietjie... #rossouwromanse laai</Text>
                        </View>
                </View>}
                <View style={styles.graphicsViewLandscape}>
                    <View>
                        <Image 
                            style={{
                                height: height,
                                width: width/4
                            }}
                            source={sideImage_1}
                            onLoad={() => updateImageLoaded(true)}
                        />
                    </View>
                    <View>
                        <Image 
                            style={{
                                height: height,
                                width: width/2
                            }}
                            source={blouCenter}
                        />
                    </View>
                    <View>
                        <Image 
                            style={{
                                height: height,
                                width: width/4
                            }}
                            source={sideImage_1}
                        />
                    </View>
                </View>
                {requestProgress
                ? <View style={styles.overlayView}><ActivityIndicator size="large" color="#0000ff" /></View>
                : <View style={[styles.overlayView, {marginTop: height*0.25}]}>
                    {/* <Text style={{
                        fontSize: height*0.08,
                        fontWeight: 'bold',
                        color: 'dodgerblue'
                    }}>RSVP</Text> */}
                    {/* <Text>{orientation}</Text>
                    <Text>Height: {round(height)}</Text>
                    <Text>Width: {round(width)}</Text> */}
                    <TextInput
                        style={{
                            height: height*0.04,
                            width: width*0.2,
                            margin: height*0.005,
                            borderWidth: 2,
                            borderColor: customBlue,
                            fontFamily: customFontFamily,
                            fontSize: height*0.025,
                            placeholderTextColor: customBlue
                        }}
                        onChangeText={onChangeFullName}
                        value={full_name}
                        placeholder='Naam en van*'
                    />
                    {/* <TextInput
                        style={{
                            height: height*0.04,
                            width: width*0.2,
                            margin: height*0.005,
                            borderWidth: 2,
                            borderColor: customBlue,
                            fontFamily: customFontFamily,
                            fontSize: height*0.025
                        }}
                        onChangeText={onChangeLastName}
                        value={last_name}
                        placeholder='Van*'
                    /> */}
                    <TextInput
                        style={{
                            height: height*0.04,
                            width: width*0.2,
                            margin: height*0.005,
                            borderWidth: 2,
                            borderColor: customBlue,
                            fontFamily: customFontFamily,
                            fontSize: height*0.025,
                            placeholderTextColor: customBlue
                        }}
                        onChangeText={onChangePhone}
                        value={phone}
                        placeholder='Kontaknommer*'
                    />
                    <TextInput
                        style={{
                            height: height*0.04,
                            width: width*0.2,
                            margin: height*0.005,
                            borderWidth: 2,
                            borderColor: customBlue,
                            fontFamily: customFontFamily,
                            fontSize: height*0.025,
                            placeholderTextColor: customBlue
                        }}
                        onChangeText={onChangeEmail}
                        value={email}
                        placeholder='E-pos*'
                    />
                    <View style={{flexDirection: 'row', width: width*0.2, justifyContent: 'space-between', alignContent: 'center'}}>
                        <Text style={{
                            // height: height*0.05,
                            paddingTop: height*0.005,
                            // marginHorizontal: width*0.02,
                            // width: width*0.18,
                            // borderWidth: 2,
                            // borderColor: 'dodgerblue',
                            color: customBlue,
                            fontFamily: customFontFamily,
                            fontSize: height*0.025
                        }}>Sien ons jou daar?</Text>
                        <Picker
                            selectedValue={status}
                            onValueChange={(itemValue, itemIndex) => onChangeStatus(itemValue)}
                            style={{
                                // height: height*0.05,
                                // padding: height*0.01,
                                // marginHorizontal: 10,
                                color: customBlue,
                                fontFamily: customFontFamily,
                                borderWidth: 2,
                                borderColor: customBlue,
                                marginVertical: height*0.005,
                                fontSize: height*0.025
                            }}
                        >
                            <Picker.Item label="Ja" value="Yes" />
                            <Picker.Item label="Nee" value="No" />
                            <Picker.Item label="Miskien" value="Maybe" />
                        </Picker>
                    </View>
                    {/* <Text style={{
                        height: height*0.05,
                        // marginHorizontal: width*0.02,
                        // width: width*0.18,
                        // borderWidth: 2,
                        // borderColor: 'dodgerblue',
                        fontSize: height*0.03,
                        fontFamily: customFontFamily,
                    }}>Dieetvoorkeure?</Text> */}
                    <TextInput
                        style={{
                            height: height*0.04,
                            width: width*0.2,
                            margin: height*0.005,
                            borderWidth: 2,
                            borderColor: customBlue,
                            fontFamily: customFontFamily,
                            fontSize: height*0.025,
                            placeholderTextColor: customBlue
                        }}
                        onChangeText={onChangeDiet}
                        value={diet}
                        placeholder='Dieetvoorkeure?*'
                    />
                    <Text style={{
                        height: height*0.04,
                        // marginHorizontal: width*0.02,
                        // width: width*0.18,
                        // borderWidth: 2,
                        // borderColor: 'dodgerblue',
                        marginTop: height*0.02,
                        borderColor: customBlue,
                        color: customBlue,
                        fontFamily: customFontFamily,
                        fontSize: height*0.025
                    }}>Wie is jou metgesel? (indien van toepassing)</Text>
                    <Pressable
                        style={{alignSelf: 'center'}}
                        onPress={() => {
                            updateCompanionsArray([...companionsArray, 
                                <CompanionInputElement 
                                    key={companionsArray.length}
                                    orientation={orientation}  
                                    height={height}
                                    width={width}
                                    index={companionsArray.length}
                                />
                            ])
                        }}
                    >
                        <View>
                            <Feather name="user-plus" size={30} color={customBlue} />
                        </View>
                    </Pressable>
                    <ScrollView style={{height: height*0.1}}>
                        {companionsArray.map(elem => elem)}
                    </ScrollView>
                    <Pressable
                        style={({ pressed }) => [
                            {
                                backgroundColor: pressed
                                ? 'rgb(210, 230, 255)'
                                : 'white',
                                borderRadius: 5, 
                                borderWidth: 3, 
                                borderColor: customBlue,
                                marginTop: height*0.15
                            }
                        ]}
                        onPress={() => {

                            if(
                                full_name == "" || full_name == null 
                                || phone == "" || phone == null
                                || email == "" || email == null
                            ) {
                                alert("Please complete all fields!")
                            } else {
                                updateRequestProgress(true)
                                submitFunction();
                            }
                        }}
                    >
                        <View style={{alignItems: 'center', margin: height*0.01}}>
                            <Text 
                                style={{
                                    paddingHorizontal: width*0.005, 
                                    paddingVertical: height*0.002, 
                                    // borderRadius: 5, 
                                    // borderWidth: 3, 
                                    // borderColor: 'dodgerblue',
                                    // borderColor: 'blue',
                                    // backgroundColor: 'deepskyblue',
                                    color: customBlue,
                                    fontFamily: customFontFamily,
                                    fontWeight: 'bold',
                                    fontSize: height*0.025
                                }}
                            >Bevestig</Text>
                        </View>
                    </Pressable>
                    {/* <Pressable
                        style={({ pressed }) => [
                            {
                                backgroundColor: pressed
                                ? 'rgb(210, 230, 255)'
                                : 'white',
                                borderRadius: 5, 
                                borderWidth: 3, 
                                borderColor: 'dodgerblue',
                                marginTop: height*0.02,
                            }
                        ]}
                        onPress={() => {
                            // Redirect to where you can change your attending status
                        }}
                    >
                        <View style={{alignItems: 'center', margin: height*0.01}}>
                            <Text 
                                style={{
                                    paddingHorizontal: width*0.005, 
                                    paddingVertical: height*0.002, 
                                    fontWeight: 'bold',
                                    fontSize: height*0.02
                                }}
                            >Change Attending Status</Text>
                        </View>
                    </Pressable> */}
                </View>
                }
            </View>
        );
    }
};

const styles = StyleSheet.create({
    containerLandscape: {
        flex: 1, 
        flexDirection: 'row',
        justifyContent: 'center', 
        alignItems: 'center', 
        // borderWidth: 5,
        // borderColor: 'red',
    },
    containerPortrait: {
        flex: 1, 
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'center', 
        // borderWidth: 5,
        // borderColor: 'red',
    },
    graphicsViewLandscape: {
        flex: 1, 
        // height: Dimensions.get("window").height,
        flexDirection: 'row',
    },
    graphicsViewPortrait: {
        flex: 1, 
        // width: Dimensions.get("window").width,
        flexDirection: 'column',
        // borderWidth: 5,
        // borderColor: 'red',
    },
    borderStyle: {
        // borderWidth: 5,
        // borderColor: 'blue',
        // height: Dimensions.get("window").height,
        // width: Dimensions.get("window").height
        height: (Dimensions.get("window").width/4)*2,
        width: (Dimensions.get("window").width/4)*2,
    },

    sideImageStyle: {
        // height: Dimensions.get("window").height,
        // width: Dimensions.get("window").height/1.5,
        // height: (Dimensions.get("window").width/4)*1.5,
        // width: Dimensions.get("window").width/4,
        height: Dimensions.get("window").height,
        width: Dimensions.get("window").width/4,
        // borderWidth: 5,
        // borderColor: 'green',
    },
    overlayView: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textStyle: {
        // fontSize: 40,
        fontSize: hp('8%'),
        fontWeight: 'bold',
        color: 'dodgerblue'
    },
    input: {
        height: 40,
        margin: 12,
        borderWidth: 1,
      },
    inputResponsive: {
        height: hp('5%'),
        width: wp('18%'),
        margin: 12,
        borderWidth: 2,
        borderColor: 'dodgerblue'
    },
    buttonText: {
        paddingHorizontal: 15, 
        paddingVertical: 5, 
        borderRadius: 5, 
        borderWidth: 3, 
        borderColor: 'dodgerblue',
        // borderColor: 'blue',
        // backgroundColor: 'deepskyblue',
        fontWeight: 'bold',
        fontSize: hp('2%')
      }
})

export default RSVPScreen;



// -----------------------------------------------------
//      Change layout according to orientation
// -----------------------------------------------------
// import React from 'react';
// import {
//   StyleSheet,
//   Text,
//   Dimensions,
//   View
// } from 'react-native';

// export default class App extends React.Component {
//   constructor() {
//     super();

//     /**
//     * Returns true if the screen is in portrait mode
//     */
//     const isPortrait = () => {
//       const dim = Dimensions.get('screen');
//       return dim.height >= dim.width;
//     };

//     this.state = {
//       orientation: isPortrait() ? 'portrait' : 'landscape'
//     };

//     // Event Listener for orientation changes
//     Dimensions.addEventListener('change', () => {
//       this.setState({
//         orientation: isPortrait() ? 'portrait' : 'landscape'
//       });
//     });

//   }

//   render() {
//     if (this.state.orientation === 'portrait') {
//       return (
//           //Render View to be displayed in portrait mode
//        );
//     }
//     else {
//       return (
//         //Render View to be displayed in landscape mode
//       );
//     }

//   }
// }