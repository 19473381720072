// Allows all Screens to have access to all the actions
import React, { useReducer } from 'react';

export default (reducer, actions, defaultValue) => {
    const Context = React.createContext();

    const Provider = ({ children }) => {
        const [state, dispatch] = useReducer(reducer, defaultValue);

        const boundActions = {};
        for (let key in actions) {
            // Loop through all actions and look up each of these action functions being passed in and call each action with dispatch
            boundActions[key] = actions[key](dispatch);
        }

        return (
            // Information being shared with all child Screens (...boundActions -> provide all boundActions)
            <Context.Provider value={{ state, ... boundActions }}>
                {children}
            </Context.Provider>
        );
    };

    return { Context, Provider };
};